// material
import { Container } from '@mui/material';
// components
import { useState, useContext, useEffect, useCallback } from 'react';
import LoadingButton from '../components/LoadingButton';
import Page from '../components/Page';
import GalleryPicker from '../components/GalleryPicker';
import { Web3Context } from '../hooks/WalletContext';

// ----------------------------------------------------------------------

export default function Buy() {
  const { buyJay, pendingRequest, address, getEthToJay, eth, fees, setRef, getMyReferralLink } =
    useContext(Web3Context);
  const [jay, setJay] = useState(0);
  const [fee, setFee] = useState(0);
  const [nfts, setNFTs] = useState({
    erc721_address: [],
    erc721_id: [],
    erc1155_address: [],
    erc1155_id: [],
    erc1155_count: []
  });
  const getImages = useCallback(async (images) => {
    setNFTs(images);
  }, []);
  const getJay = useCallback(async () => {
    const _val = fee.toFixed(10);
    setJay(
      await getEthToJay('BUY', '0xDA7C0810cE6F8329786160bb3d1734cf6661CA6E', 'native', _val / 2)
    );
  }, [getEthToJay, fee]);
  const buy = async () => {
    await buyJay(nfts, Math.ceil(fee * 10 ** 10) / 10 ** 10);
  };
  useEffect(() => {
    if (window.location.href.toLowerCase().indexOf('sellNFTs') <= 0) {
      setRef(
        window.location.href
          .toLowerCase()
          .substring(window.location.href.lastIndexOf('/') + 1, window.location.href.length)
      );
    }
  }, []);
  useEffect(() => {
    getMyReferralLink();
  }, [address]);
  useEffect(() => {
    if (fee > 0) getJay();
    else setJay(0);
  }, [fee, getJay]);

  useEffect(() => {
    if (nfts.erc1155_id.length + nfts.erc721_id.length >= 100)
      setFee(((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[0]) / 2);
    else setFee((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[0]);
  }, [nfts, fees]);

  return (
    <Page
      title="Jaypeggers | Sell NFTs"
      sx={{
        position: 'relative',
        minHeight: '100%',
        maxHeight: '-webkit-fill-available',
        height: '-webkit-fill-available',
        width: '-webkit-fill-available'
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          bottom: 0,
          mb: ['120px', '120px', '130px', '130px'],
          left: 0,
          right: 0
        }}
      >
        <GalleryPicker returnImages={getImages} address={address} approved={false} />
      </Container>
      <LoadingButton
        onClick={buy}
        loading={pendingRequest}
        sx={{
          width: '70%',
          p: '10px',
          fontSize: ['14px', '16px', '24px'],
          minHeight: 100,
          position: 'absolute',
          bottom: ['15px', '15px', '25px', '25px'],
          left: '15%',
          zIndex: 6,
          display: 'block',
          borderRadius: '3em',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important',
          border: 'none'
        }}
        disabled={nfts.erc1155_id.length + nfts.erc721_id.length === 0 || fee > eth}
        variant="contained"
      >
        {fee > eth ? (
          <div style={{ width: '100%' }}>{fee - eth} more ETH + gas required</div>
        ) : (
          <>
            <div style={{ width: '100%' }}>
              Sell {nfts.erc1155_id.length + nfts.erc721_id.length} NFTs for{' '}
              {Math.floor(Number(jay) * 100000) / 100000} $JAY
            </div>
            <div style={{ fontSize: '14px', width: '100%' }}>
              ( Fee {fee?.toFixed(8) || '?'} $ETH )
            </div>
          </>
        )}
      </LoadingButton>
    </Page>
  );
}
