import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
  box: {
    zIndex: '6',
    fontSize: 14,
    width: 185,
    height: 56,
    border: 'none',
    lineHeight: '56px',
    borderRadius: '3em',
    position: 'fixed',
    top: 16,
    left: 320,
    textAlign: 'center',
    '@media (max-width: 1200px)': {
      left: 117
    },
    '@media (max-width: 700px)': {
      left: 90
    },
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
  }
});

function Cart(props) {
  const { classes, value, ...other } = props;
  return (
    <Box className={classes.box} {...other}>
      <span style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: '18px' }}>
        Est. Loss Harvest ${Number(value).toFixed(2)}
      </span>
    </Box>
  );
}

Cart.defaultProps = {
  value: 0
};

Cart.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number
};

export default withStyles(styles)(Cart);
