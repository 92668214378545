// material
import { Container } from '@mui/material';
// components
import { useState, useContext, useCallback } from 'react';
import LoadingButton from '../components/LoadingButton';
import Page from '../components/Page';
import Vault from '../components/GalleryPicker/vault';
import { Web3Context } from '../hooks/WalletContext';

// ----------------------------------------------------------------------

export default function BuyNFT() {
  const { buyNfts, pendingRequest, geth, eth, fees, approveBuy, buyApproved } =
    useContext(Web3Context);
  // console.log(buyApproved);
  const [nfts, setNFTs] = useState({
    erc721_address: [],
    erc721_id: [],
    erc1155_address: [],
    erc1155_id: [],
    erc1155_count: []
  });
  const getImages = useCallback(async (images) => {
    setNFTs(images);
  }, []);
  const buy = async () => {
    await buyNfts(
      nfts,
      Math.ceil((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[1] * 10 ** 10) / 10 ** 10
    );
  };
  return (
    <Page
      title="Jaypeggers | Thrift Store"
      sx={{
        position: 'relative',
        minHeight: '100%',
        maxHeight: '-webkit-fill-available',
        height: '-webkit-fill-available',
        width: '-webkit-fill-available'
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          bottom: 0,
          mb: ['120px', '120px', '130px', '130px'],
          left: 0,
          right: 0
        }}
      >
        <Vault
          returnImages={getImages}
          address="0x130F0002b4cF5E67ADf4C7147ac80aBEe7b3Fe0a"
          approved
        />
      </Container>
      <LoadingButton
        onClick={
          Number(buyApproved) > Number((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[2])
            ? buy
            : approveBuy
        }
        loading={pendingRequest}
        sx={{
          width: '70%',
          p: '10px',
          fontSize: ['14px', '16px', '24px'],
          minHeight: 100,
          position: 'absolute',
          bottom: ['15px', '15px', '25px', '25px'],
          left: '15%',
          zIndex: 6,
          display: 'block',
          borderRadius: '3em',
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important',
          border: 'none'
        }}
        disabled={
          Number(buyApproved) >
            Number((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[2]) &&
          (nfts.erc1155_id.length + nfts.erc721_id.length === 0 ||
            (nfts.erc1155_id.length + nfts.erc721_id.length) * fees[1] > eth ||
            nfts.erc1155_id.length + nfts.erc721_id.length * fees[2] > geth)
        }
        variant="contained"
      >
        {Number(buyApproved) <
        Number((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[2]) ? (
          <div style={{ width: '100%' }}>Approve JAY</div>
        ) : nfts.erc1155_id.length + nfts.erc721_id.length > geth ? (
          <div style={{ width: '100%' }}>
            {((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[2]).toFixed(4) - geth} more
            JAY required
          </div>
        ) : (nfts.erc1155_id.length + nfts.erc721_id.length) * fees[1] > eth ? (
          <div style={{ width: '100%' }}>
            {(nfts.erc1155_id.length + nfts.erc721_id.length) * fees[1] - eth} more ETH + gas
            required
          </div>
        ) : (
          <>
            <div style={{ width: '100%' }}>
              Buy {nfts.erc1155_id.length + nfts.erc721_id.length} NFTs for{' '}
              {((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[2]).toFixed(4)} $JAY
            </div>
            <div style={{ fontSize: '14px', width: '100%' }}>
              Fee {((nfts.erc1155_id.length + nfts.erc721_id.length) * fees[1]).toFixed(8)} $ETH
            </div>
          </>
        )}
      </LoadingButton>
    </Page>
  );
}
