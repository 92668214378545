// routes
import { Web3OnboardProvider, init } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import Onboard from '@web3-onboard/core';
import dcentModule from '@web3-onboard/dcent';
import UAuth from '@uauth/js';
import uauthBNCModule from '@uauth/web3-onboard';
import fortmaticModule from '@web3-onboard/fortmatic';
import gnosisModule from '@web3-onboard/gnosis';
import keepkeyModule from '@web3-onboard/keepkey';
import keystoneModule from '@web3-onboard/keystone';
import magicModule from '@web3-onboard/magic';
import mewModule from '@web3-onboard/mew';
import portisModule from '@web3-onboard/portis';
import torusModule from '@web3-onboard/torus';
import trezorModule from '@web3-onboard/trezor';
import walletConnectModule from '@web3-onboard/walletconnect';
import walletLinkModule from '@web3-onboard/walletlink';
import web3authModule from '@web3-onboard/web3auth';
import { useContext } from 'react';

import UDBanner from './components/Banner';

import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { Web3Provider, Web3Context } from './hooks/WalletContext';
import TxFail from './components/TxFail';

const INFURA_KEY = '3f7945f3464d4e1e8e0f2b31a402ce60';

const RPC_URL = 'https://eth-mainnet.g.alchemy.com/v2/Tgs_4ZYu8Ut9JaMopAlQGvEVFwSsIyiS'; // `https://mainnet.infura.io/v3/${INFURA_KEY}`;

const FORTMATIC_KEY = 'pk_live_6BABD4F525A37DDA';
const PORTIS_KEY = '972fc3ee-0180-47a9-868d-efdc1971a439';

const APP_URL = 'https://app.jaypeggers.com';
const CONTACT_EMAIL = 'jaypeggers.nft@ud.me';
const APP_NAME = 'Jaypeggers';

const injected = injectedModule();
const dcent = dcentModule();
const fortmatic = fortmaticModule({ apiKey: FORTMATIC_KEY });
const gnosis = gnosisModule();
const keepkey = keepkeyModule();
const keystone = keystoneModule();
const magic = magicModule({
  apiKey: 'pk_live_DED9257697811CEE',
  userEmail: localStorage.getItem('magicUserEmail')
});
const mew = mewModule();
const portis = portisModule({ apiKey: PORTIS_KEY });
const torus = torusModule();
const trezor = trezorModule({
  email: CONTACT_EMAIL,
  appUrl: APP_URL
});
const walletConnect = walletConnectModule({
  requiredChains: [1],
  projectId: '7cb93cee333b9e93fdeb3ea15242d479',
  dappUrl: 'https://app.jaypeggers.com'
});
const walletLink = walletLinkModule({ darkMode: true });
const web3auth = web3authModule({
  clientId:
    'DJuUOKvmNnlzy6ruVgeWYWIMKLRyYtjYa9Y10VCeJzWZcygDlrYLyXsBQjpJ2hxlBO9dnl8t9GmAC2qOP5vnIGo'
});

const uauth = new UAuth({
  clientID: '51fff444-c1f1-47e0-a651-f1c156f5b579',
  redirectUri: APP_URL,
  scope: 'openid wallet'
});
/*
const uauth = new UAuth({
  clientID: '434301da-73d7-405a-a99d-4bb634673b28',
  redirectUri: 'http://localhost:3000',
  scope: 'openid wallet'
});
*/
const uauthBNCOptions = {
  uauth,
  walletconnect: {
    infuraId: INFURA_KEY,
    projectId: '7cb93cee333b9e93fdeb3ea15242d479'
  }
};
const uauthModule = uauthBNCModule(uauthBNCOptions);

const web3Onboard = Onboard({
  apiKey: '9167d572-ad3a-4fad-ad56-d3ea8576595a',
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum mainnet',
      rpcUrl: RPC_URL
    }
  ],
  appMetadata: {
    name: 'Jaypeggers',
    icon: '/static/eggload.svg', // svg string icon
    description: 'NFT tax loss harvesting'
  },
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: true,
      minimal: false
    },
    mobile: {
      position: 'topRight',
      enabled: true,
      minimal: true
    }
  },
  connect: {
    showSidebar: true
  },
  wallets: [
    injected,
    uauthModule,
    walletConnect,
    walletLink,
    trezor,
    magic,
    portis,
    fortmatic,
    mew,
    gnosis,
    dcent,
    keepkey,
    keystone,
    torus,
    web3auth
  ]
});

// ----------------------------------------------------------------------

export default function App() {
  const { chainId } = useContext(Web3Context);
  return (
    <ThemeConfig>
      <UDBanner />

      <Web3OnboardProvider web3Onboard={web3Onboard}>
        <Web3Provider>
          {chainId !== 1 && chainId !== 5 ? (
            <h1>Wrong network. Switch to ETH.</h1>
          ) : (
            <>
              <ScrollToTop />
              <TxFail />
              <GlobalStyles />
              <Router />
            </>
          )}
        </Web3Provider>
      </Web3OnboardProvider>
    </ThemeConfig>
  );
}
