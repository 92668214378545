// material
import { useContext, useEffect, useState } from 'react';

import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { JAY_CONTRACT_ } from '../../../hooks/constants/contracts';
import { Web3Context } from '../../../hooks/WalletContext';

// utils
//
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.grey[600],
  backgroundColor: '#fce7df',
  paddingBottom: '18px'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.palette.grey[600],
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.grey[600]
}));

// ----------------------------------------------------------------------

export default function JayApy(props) {
  const { getEthToJay, chainId } = useContext(Web3Context);
  const [apy, setApy] = useState('');
  useEffect(() => {
    let isMounted = true;
    const getApy = async () => {
      const _apy = await getEthToJay('SELL', props.tokenAddress, props.tokenCounterpartAddress, 1);
      // console.log(_apy);
      const num = props.name === 'JAY' ? 0.0009 : 0.9;
      if (isMounted) {
        if (_apy > 0)
          setApy(
            (((Number(_apy) / num - 1) / days(new Date(), new Date(props.date))) * 36500)
              .toFixed(2)
              .toString() || ''
          );
      }
    };

    getApy();
    return () => {
      isMounted = false;
    };
  }, [chainId, props.tokenAddress]);
  const days = (date1, date2) => {
    const difference = date1.getTime() - date2.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };
  return (
    <RootStyle
      sx={{
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '3em',
        zIndex: 1
      }}
    >
      <IconWrapperStyle>
        <Iconify icon="mdi:lock-outline" width={30} height={30} />
      </IconWrapperStyle>
      <Typography variant="h3">{apy}%</Typography>
      <Tooltip title="APY is calculated as (% of eth gained) / (total days since launched) * (365 days)">
        <Typography variant="subtitle2" sx={{ opacity: 0.72, zIndex: 7 }}>
          {props.name} rate of increase based
        </Typography>
      </Tooltip>
      <Typography variant="subtitle2" sx={{ opacity: 0.72, zIndex: 7 }}>
        on backing (annualized)
      </Typography>
    </RootStyle>
  );
}
JayApy.propTypes = {
  tokenAddress: PropTypes.string.isRequired,
  tokenCounterpartAddress: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};
