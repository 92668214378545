import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { FormControlLabel, Checkbox, Box, IconButton, Tooltip } from '@mui/material';
import WarningIcon from '@material-ui/icons/Warning';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Web3Context } from '../../hooks/WalletContext';
import ImageContainerVault from './ImageContainerVault';

function AssetContainer(props) {
  const { pendingRequest, checkBalances, succeed, setLoading } = useContext(Web3Context);
  const { returnImages, address, collection } = props;

  const [state, setState] = useState({
    images: [],
    nextFallback: '',
    next: '',
    selectAll: false,
    fallback: 0,
    reload: false
  });
  const { images, nextFallback, next, selectAll, fallback, reload } = state;

  const getAssets = useCallback(async () => {
    try {
      if (address !== '') {
        // replace with your Alchemy api key
        const apiKey = '6Wja7xUMnVb9zeft1oOf8GaSxJ1CKImm';
        const baseURL = `https://eth-mainnet.g.alchemy.com/nft/v2/${apiKey}/getNFTs/`;
        // replace with the wallet address you want to query for NFTs
        // // console.log(collection);
        const str = `&contractAddresses[]=${collection?.contract}`;
        const config = {
          method: 'get',
          url: `${baseURL}?owner=${address}${str}${
            next !== '' && next !== '' ? `&pageKey=${next}` : ''
          }`
        };

        const response = await axios(config);
        const arr = response.data.ownedNfts;
        const _assest = arr.map((a) => ({
          key: `${a.contract.address}-${a.id.tokenId}`,
          index: `${a.contract.address}-${a.id.tokenId}`,
          id: a.id.tokenId,
          fullId: a.id.tokenId,
          src: a.media[0].gateway,
          selected: selectAll,
          title:
            a.title !== ''
              ? a.title
              : `Contract: ${a.contract.address}\n\nId: ${BigInt(a.id.tokenId).toString()}`,
          address: a.contract.address,
          type: a.id.tokenMetadata.tokenType,
          approving: false,
          link: `https://opensea.io/assets/ethereum/${a.contract.address}/${BigInt(a.id.tokenId)}`
        }));
        /* const balances = await checkBalances(
          address,
          _assest.map((i) => i.address),
          _assest.map((i) => i.fullId),
          _assest.map((i) => i.type === 'ERC721')
        );

        const assetsFinal = _assest
          .map((a, i) => ({
            ...a,
            balance: balances[i]
          }))
          .filter((d) => d.balance !== 0); */

        return {
          images: [...images, ..._assest],
          next: response.data.pageKey || null,
          reload: false
        };
      }
    } catch {
      return { next: state.next + 1 };
    }
  }, [address, selectAll, images, checkBalances]);

  const onSelectAllClick = useCallback(() => {
    const imageList = images.map((img) => {
      img.selected = !selectAll;
      return img;
    });
    setState((s) => ({ ...s, images: imageList, selectAll: !s.selectAll }));
  }, [images, selectAll]);
  const onImageClick = useCallback(
    (id) => {
      const imageList = images.map((img) => {
        if (img.index === id) {
          img.selected = !img.selected;
        }
        return img;
      });
      setState((s) => ({ ...s, images: imageList }));
    },
    [images]
  );
  const cancel = (id) => {
    const imageList = images.map((img) => {
      if (img.index === id) {
        img.selected = false;
      }
      return img;
    });
    setState((s) => ({ ...s, images: imageList }));
  };

  useEffect(() => {
    let isSubscribed = true;
    async function fetchData() {
      if (
        images.length < 50 &&
        ((next !== null && next !== '') || (nextFallback !== null && nextFallback !== ''))
      ) {
        // // // console.log('Renderer - 1');
        setTimeout(async () => {
          const newState = await getAssets();
          if (isSubscribed) setState((s) => ({ ...s, ...newState }));
        }, 2000);
      }
    }
    fetchData();
    return () => (isSubscribed = false);
  }, [images.length, next, getAssets, nextFallback]);

  useEffect(() => {
    let isSubscribed = true;
    if (address !== '' && fallback > 0) {
      // // // console.log('Renderer - 2');
      setTimeout(async () => {
        const newState = await getAssets();
        if (isSubscribed) setState((s) => ({ ...s, ...newState }));
      }, 2000);
    }
    return () => (isSubscribed = false);
  }, [address, fallback, getAssets]);

  const hasFetchedData = useRef(false);
  useEffect(() => {
    async function fetchData() {
      if (address !== '' && !hasFetchedData.current) {
        hasFetchedData.current = true;
        const newState = await getAssets();
        setState((s) => ({ ...s, ...newState }));
      }
    }

    fetchData();
  }, [address, getAssets]);
  useEffect(() => {
    let isSubscribed = true;
    async function fetchData() {
      if (succeed && isSubscribed) {
        setState((s) => ({ ...s, images: [], nextFallback: '', next: '', reload: true }));
      }
    }

    fetchData();
    return () => (isSubscribed = false);
  }, [succeed]);

  useEffect(() => {
    let isSubscribed = true;
    async function fetchData() {
      if (reload) {
        // // // console.log('Renderer - 4');
        const newState = await getAssets();
        if (isSubscribed) setState((s) => ({ ...s, ...newState }));
      }
    }

    fetchData();
    return () => (isSubscribed = false);
  }, [reload, getAssets]);

  useEffect(() => {
    let isSubscribed = true;
    async function fetchData() {
      if (
        selectAll &&
        images.length < 500 &&
        ((next !== null && next !== '') || (nextFallback !== null && nextFallback !== ''))
      ) {
        setLoading(true);
        setTimeout(async () => {
          const newState = await getAssets();
          if (isSubscribed) setState((s) => ({ ...s, ...newState }));
        }, 1000);
      } else {
        setLoading(false);
      }
    }

    fetchData();
    return () => (isSubscribed = false);
  }, [selectAll, nextFallback, images.length, next, getAssets, setLoading, collection.slug]);

  useEffect(() => {
    if (!pendingRequest) {
      setState((s) => ({
        ...s,
        selectAll: false,
        images: s.images.map((img) => {
          img.selected = false;
          return img;
        })
      }));
    }
  }, [pendingRequest]);

  useEffect(() => {
    // // // console.log(images.length);
    const nfts = {
      erc721_address: images.filter((i) => i.selected && i.type === 'ERC721').map((i) => i.address),
      erc721_id: images
        .filter((i) => i.selected && i.type === 'ERC721')
        .map((i) => i.id.toString()),
      erc1155_address: images
        .filter((i) => i.selected && i.type === 'ERC1155')
        .map((i) => i.address),
      erc1155_id: images
        .filter((i) => i.selected && i.type === 'ERC1155')
        .map((i) => i.id.toString()),
      erc1155_count: images.filter((i) => i.selected && i.type === 'ERC1155').map(() => '1')
    };
    returnImages(nfts);
  }, [returnImages, next, images]);

  return (
    <>
      <div style={{ margin: '10px' }}>
        <FormControlLabel
          label="Select All (Max 500)"
          control={
            <Checkbox
              onChange={onSelectAllClick}
              checked={selectAll}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
        <div
          style={{
            position: 'absolute',
            right: '30px',
            top: '1px'
          }}
        >
          <Tooltip title="Caution: Floor and volume data is from Opensea and NFTbank and may not be accurate">
            <IconButton aria-label="Caution: data is from Opensea and Alchemy and may not be accurate">
              <WarningIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Box
        type="div"
        id="scrollableDiv"
        sx={{
          height: [
            'calc(100vh - 325px)',
            'calc(100vh - 325px)',
            'calc(100vh - 345px)',
            'calc(100vh - 345px)'
          ],
          overflow: 'auto',
          display: 'flex'
        }}
      >
        {address !== '' ? (
          <InfiniteScroll
            scrollThreshold="1000px"
            style={{ minWidth: '100%', paddingTop: 3, overflowX: 'hidden' }}
            scrollableTarget="scrollableDiv"
            dataLength={images.length}
            next={getAssets}
            loader={
              <div style={{ textAlign: 'center', minWidth: '100%' }}>
                <h4>Loading...</h4>
              </div>
            }
            endMessage={
              <div style={{ textAlign: 'center', minWidth: '100%' }}>
                <b>That's all there is to see!</b>
              </div>
            }
            hasMore={next !== null}
          >
            {images.map((img) => (
              <ImageContainerVault
                key={img.key}
                cancel={cancel}
                onImageClick={onImageClick}
                img={img}
                floor={collection.floor}
                thirty={collection.thirty}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <h3 style={{ width: '100%', textAlign: 'center' }}>Connect wallet to load NFTs</h3>
        )}
      </Box>
    </>
  );
}
AssetContainer.propTypes = {
  returnImages: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  collection: PropTypes.object.isRequired
};

export default AssetContainer;
