import React, { useContext } from 'react';

import { Box } from '@mui/material';
import { Web3Context } from '../hooks/WalletContext';

function Loader() {
  const { pendingRequest, loader } = useContext(Web3Context);
  if (pendingRequest || loader) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 8
        }}
      >
        <Box
          component="img"
          src="/static/big-egg42.png"
          sx={{
            width: 400,
            p: '4px',
            animation: 'rotation 4s infinite linear',
            margin: 'auto',
            position: 'absolute',
            top: 'calc(50vh - 200px)',
            left: 'calc(50vw - 200px)'
          }}
        />
      </div>
    );
  }

  return <div />;
}

export default Loader;
