// material
import { Container, Link } from '@mui/material';
import { useContext, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import BuySwaper from '../sections/@dashboard/app/BuySwaper';
import { JAY_CONTRACT_, USDC_TOKEN_ } from '../hooks/constants/contracts';
import { Web3Context } from '../hooks/WalletContext';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',

  marginTop: '0',
  maxWidth: '95vw',
  '@media (min-width: 600px)': {
    marginTop: '100px'
  }
}));
export default function Swap() {
  const USDC_TOKEN = USDC_TOKEN_;
  const JAY_CONTRACT = JAY_CONTRACT_;
  const { chainId } = useContext(Web3Context);
  return (
    <Page title="Jaypeggers | Swap">
      <Container>
        <RootStyle>
          <BuySwaper />
        </RootStyle>
      </Container>
    </Page>
  );
}
