import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { FormControlLabel, Checkbox, Button, Box, Tooltip, IconButton } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import WarningIcon from '@material-ui/icons/Warning';
import Multiselect from 'multiselect-react-dropdown';
import './style.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Web3Context } from '../../hooks/WalletContext';
import ImageContainer from './ImageContainer';
import { useAsyncEffect } from '../../hooks/useEffectAsync';
import Cart from '../Cart';

function GalleryPicker(props) {
  const {
    approve,
    pendingRequest,
    checkBalances,
    checkApprovals,
    succeed,
    setLoading,
    userNftbank,
    collections,
    getFloor
  } = useContext(Web3Context);
  const { returnImages, address, approved } = props;

  const [filters, setFilters] = useState({
    filters: [],
    next: null,
    reloadFilters: false
  });

  const [state, setState] = useState({
    images: [],
    approvedAddresses: [],
    next: '',
    selectedFilters: [],
    hideThirty: false,
    hideFloorOver: false,
    hideOverThirty: false,
    hideFloorUnder: false,
    selectAll: false,
    fallback: 0,
    reload: false
  });
  const {
    images,
    approvedAddresses,
    next,
    selectedFilters,
    hideThirty,
    hideFloorOver,
    hideOverThirty,
    hideFloorUnder,
    selectAll,
    fallback,
    reload
  } = state;

  const [showFilters, setShowFilters] = useState(false);

  const getAssets = useCallback(async () => {
    try {
      if (address !== '') {
        // replace with your Alchemy api key
        const apiKey = '6Wja7xUMnVb9zeft1oOf8GaSxJ1CKImm';
        const baseURL = `https://eth-mainnet.g.alchemy.com/nft/v2/${apiKey}/getNFTs/`;
        // replace with the wallet address you want to query for NFTs
        let str = '';
        selectedFilters.forEach((f) => (str += `&contractAddresses[]=${f.id}`));
        const config = {
          method: 'get',
          url: `${baseURL}?owner=${address}${str}${
            next !== '' && next !== '' ? `&pageKey=${next}` : ''
          }`
        };

        const response = await axios(config);
        const arr = response.data.ownedNfts;
        /* const _arr = await Promise.all(
          arr.map(async (nft) => {
            // // console.log(nft.contract.address);
            return {
              ...nft,
              floor: await getFloor(nft.contract.address)
            };
          })
        );
        // // console.log(_arr); */
        const _assest = arr
          .filter(
            (d) =>
              images.findIndex(
                (i) => i.key === d.contract.address + BigInt(d.id.tokenId).toString()
              ) < 0
          )
          .filter((d) => {
            // // console.log(hideFloorUnder);
            if (hideFloorUnder) {
              const collection = filters?.filters?.find((c) => d.contract.address === c.id);
              return (
                collection?.data?.floor_price_eth === '?' ||
                Number(collection?.data?.floor_price_eth) <= 0.001
              );
            }
            return true;
          })
          .filter((d) => {
            // // console.log(hideOverThirty);
            if (hideOverThirty) {
              const collection = filters?.filters?.find((c) => d.contract.address === c.id);
              return (
                collection?.data?.floor_price_eth === '?' ||
                Number(collection?.data?.floor_price_eth) <= 0.01
              );
            }
            return true;
          })
          .map((a) => ({
            key: `${a.contract.address}-${a.id.tokenId}`,
            index: `${a.contract.address}-${a.id.tokenId}`,
            id: a.id.tokenId,
            fullId: a.id.tokenId,
            src: a.media[0].gateway,
            selected: selectAll,
            floor: a?.contractMetadata?.openSea?.floorPrice,
            title:
              a.title !== ''
                ? a.title
                : `Contract: ${a.contract.address}\n\nId: ${BigInt(a.id.tokenId).toString()}`,
            address: a.contract.address,
            type: a.id.tokenMetadata.tokenType,
            approving: false,
            link: `https://opensea.io/assets/ethereum/${a.contract.address}/${BigInt(
              a.id.tokenId
            ).toString()}`
          }));
        const balances = await checkBalances(
          address,
          _assest.map((i) => i.address),
          _assest.map((i) => i.fullId),
          _assest.map((i) => i.type === 'ERC721')
        );
        const approvals = await checkApprovals(
          address,
          _assest.map((i) => i.address),
          _assest.map((i) => i.type === 'ERC721')
        );

        const assetsFinal = _assest
          .map((a, i) => ({
            ...a,
            balance: 1,
            approved: approved || approvals[a.address]
          }))
          .filter((d) => d.balance !== 0);
        return {
          images: [...images, ...assetsFinal],
          next: response.data.pageKey || null,
          reload: false
        };
      }
    } catch {
      return { next: state.next + 1 };
    }
  }, [address, selectAll, selectedFilters, images, approved, checkApprovals, checkBalances]);

  const getFilters = useCallback(async () => {
    // // console.log('FILS');
    try {
      const apiKey = '6Wja7xUMnVb9zeft1oOf8GaSxJ1CKImm';
      const response = await axios.get(
        `https://eth-mainnet.g.alchemy.com/nft/v2/${apiKey}/getContractsForOwner?owner=${address}
      ${filters.next ? `&pageKey=${filters.next}` : ''}`
      );

      // replace with the wallet address you want to query for NFTs
      // // console.log('FILS');
      const fils = await Promise.all(
        response.data.contracts.map(async (c) => {
          if (c.address.length > 0) {
            return {
              name: `${c.name}      (${c.address})`,
              id: c.address,
              data: { floor_price_eth: c?.opensea?.floorPrice }
            };
          }
        })
      );
      return {
        filters: [...filters.filters, ...fils].sort((a, b) => a.name.localeCompare(b.name)),
        next: response.data?.pageKey ? response.data?.pageKey : null,
        reloadFilters: false
      };
    } catch {
      // // console.log('catch');
      return {
        filters: [],
        next: null,
        reloadFilters: true
      };
    }
  }, [address, filters.filters, filters.next, collections]);

  const onSelect = (selectedFilters) => {
    setState((s) => ({
      ...s,
      images: [],
      next: '',
      selectedFilters,
      reload: true,
      selectAll: false
    }));
  };

  const _getAssets = useCallback(async () => getAssets(), [fallback, getAssets]);

  const setNextAssets = useCallback(async () => {
    const newAssets = await _getAssets();
    setState((s) => ({
      ...s,
      ...newAssets
    }));
  }, [_getAssets]);

  const setApproved = async (id, contract) => {
    const imageList = images.map((img) => {
      if (img.address === contract) {
        img.approving = true;
      }
      return img;
    });
    setState((s) => ({
      ...s,
      images: imageList
    }));
    if (await approve(contract)) {
      const imageList = images.map((img) => {
        if (img.address === contract) {
          img.approving = false;
          img.approved = true;
        }
        return img;
      });
      setState((s) => ({
        ...s,
        images: imageList,
        approvedAddresses: [...approvedAddresses, contract]
      }));
    } else {
      const imageList = images.map((img) => {
        if (img.address === contract) {
          img.approving = false;
        }
        return img;
      });
      setState((s) => ({
        ...s,
        images: imageList
      }));
    }
  };

  const onSelectAllClick = useCallback(() => {
    const imageList = images.map((img) => {
      img.selected = !selectAll;
      if (approved || img.approved || approvedAddresses.find((a) => a === img.address)) {
        img.approved = true;
      }
      return img;
    });
    setState((s) => ({ ...s, images: imageList, selectAll: !s.selectAll }));
  }, [approvedAddresses, images, approved, selectAll]);
  const onImageClick = useCallback(
    (id) => {
      const imageList = images.map((img) => {
        // // console.log(img.index);
        // // console.log(id);
        if (img.index === id.img) {
          img.selected = !img.selected;
          img.lastSale = id.lastSale;
        }
        if (approved || img.approved || approvedAddresses.find((a) => a === img.address)) {
          img.approved = true;
        }
        return img;
      });
      setState((s) => ({ ...s, images: imageList }));
    },
    [approvedAddresses, images, approved]
  );
  const cancel = (id) => {
    const imageList = images.map((img) => {
      if (img.index === id) {
        img.selected = false;
      }
      return img;
    });
    setState((s) => ({ ...s, images: imageList }));
  };

  useAsyncEffect(
    1,
    _getAssets,
    setState,
    useCallback(() => images.length < 50 && next !== null && next !== '', [images.length, next]),
    2000
  );

  useAsyncEffect(
    2,
    _getAssets,
    setState,
    useCallback(() => address !== '' && fallback > 0, [address, fallback]),
    2000
  );

  useAsyncEffect(
    3,
    _getAssets,
    setState,
    useCallback(() => address !== '', [address]),
    0,
    false
  );

  useAsyncEffect(
    4,
    _getAssets,
    setState,
    useCallback(() => reload, [reload]),
    0
  );

  useAsyncEffect(
    5,
    _getAssets,
    setState,
    useCallback(() => {
      if (selectAll && images.length < 500 && next !== null && next !== '') {
        setLoading(true);
        return true;
      }
      setLoading(false);
      return false;
    }, [images.length, next, selectAll, setLoading]),
    1000
  );
  useAsyncEffect(
    6,
    getFilters,
    setFilters,
    useCallback(() => address, [address]),
    0,
    false
  );
  useAsyncEffect(
    7,
    getFilters,
    setFilters,
    useCallback(
      () => filters?.reloadFilters || (filters.next !== '' && filters.next !== null),
      [filters?.next, filters?.reloadFilters]
    ),
    0
  );

  useEffect(() => {
    if (succeed) {
      setState((s) => ({ ...s, images: [], next: '', reload: true }));
    }
  }, [succeed]);

  useEffect(() => {
    if (!pendingRequest) {
      setState((s) => ({
        ...s,
        selectAll: false,
        images: s.images.map((img) => {
          img.selected = false;
          return img;
        })
      }));
    }
  }, [pendingRequest]);

  useEffect(() => {
    // // // console.log(images.length);
    const nfts = {
      erc721_address: images
        .filter((i) => (approved || i.approved) && i.selected && i.type === 'ERC721')
        .map((i) => i.address),
      erc721_id: images
        .filter((i) => (approved || i.approved) && i.selected && i.type === 'ERC721')
        .map((i) => i.id.toString()),
      erc1155_address: images
        .filter((i) => (approved || i.approved) && i.selected && i.type === 'ERC1155')
        .map((i) => i.address),
      erc1155_id: images
        .filter((i) => (approved || i.approved) && i.selected && i.type === 'ERC1155')
        .map((i) => i.id.toString()),
      erc1155_count: images
        .filter((i) => (approved || i.approved) && i.selected && i.type === 'ERC1155')
        .map(() => '1')
    };
    returnImages(nfts);
  }, [returnImages, next, approved, images]);

  return (
    <>
      <Cart
        value={
          images.filter((i) => i.selected).length > 0 &&
          images
            .filter((i) => i.selected)
            .map((o) => (Number(o.lastSale) > 0 ? o.lastSale : 0))
            .reduce((a, c) => a + c)
        }
      />
      <Multiselect
        style={{ lineHeight: '56px' }}
        options={filters.filters} // Options to display in the dropdown
        onSelect={onSelect} // Function will trigger on select event
        onRemove={onSelect} // Function will trigger on remove event
        displayValue="name" // Property name to display in the dropdown options
        placeholder="Filter by Collection(s)"
        loading={filters.filters.length === 0}
      />
      {!showFilters ? (
        <Button onClick={() => setShowFilters(true)}>show filters</Button>
      ) : (
        <div style={{ margin: '10px' }}>
          <FormControlLabel
            label="Floor under 0.001 ETH or unnknown"
            control={
              <Checkbox
                onChange={(a) => {
                  setState((s) => ({
                    ...s,
                    images: [],
                    next: '',
                    reload: true,
                    hideFloorUnder: a.target.checked,
                    selectAll: false
                  }));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label="Floor under 0.01 ETH"
            control={
              <Checkbox
                onChange={(a) => {
                  setState((s) => ({
                    ...s,
                    images: [],
                    next: '',
                    reload: true,
                    hideOverThirty: a.target.checked,
                    selectAll: false
                  }));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                label=""
              />
            }
          />
          <FormControlLabel
            label="Select All (Max 500)"
            control={
              <Checkbox
                onChange={onSelectAllClick}
                checked={selectAll}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <Button onClick={() => setShowFilters(false)}>hide filters</Button>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          right: '30px',
          top: '3px'
        }}
      >
        <Tooltip title="Caution: Floor and volume data is from Opensea and NFTbank and may not be accurate">
          <IconButton aria-label="Caution: data is from Opensea and Alchemy and may not be accurate">
            <WarningIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Box
        type="div"
        id="scrollableDiv"
        sx={{
          height: [
            'calc(100vh - 325px)',
            'calc(100vh - 325px)',
            'calc(100vh - 345px)',
            'calc(100vh - 345px)'
          ],
          overflow: 'auto',
          display: 'flex'
        }}
      >
        {address !== '' ? (
          <InfiniteScroll
            scrollThreshold="1000px"
            style={{ minWidth: '100%', paddingTop: 3, overflowX: 'hidden' }}
            scrollableTarget="scrollableDiv"
            dataLength={images.length}
            next={() => {
              if (address !== '') setNextAssets();
            }}
            loader={
              <div style={{ textAlign: 'center', minWidth: '100%' }}>
                <h4>Loading...</h4>
              </div>
            }
            endMessage={
              <div style={{ textAlign: 'center', minWidth: '100%' }}>
                <b>That's all there is to see!</b>
              </div>
            }
            hasMore={next !== null}
          >
            {images.map((img) => (
              <ImageContainer
                key={img.key}
                cancel={cancel}
                onImageClick={onImageClick}
                setApproved={setApproved}
                img={img}
                approved={approved}
                filters={filters.filters}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <h3 style={{ width: '100%', textAlign: 'center' }}>Connect wallet to load NFTs</h3>
        )}
      </Box>
    </>
  );
}
GalleryPicker.propTypes = {
  returnImages: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  approved: PropTypes.bool.isRequired
};

export default GalleryPicker;
