import React, { useContext } from 'react';

import { Box, Button } from '@mui/material';
import { TwitterShareButton } from 'react-share';
import { Web3Context } from '../hooks/WalletContext';

function TxFail() {
  const { fail, succeed, closeFail, succeedMsg, myRef } = useContext(Web3Context);
  if (fail || succeed) {
    // console.log(myRef);
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 8
        }}
      >
        <Box
          component="div"
          sx={{
            width: 500,
            p: '30px',
            borderRadius: '3em',
            background: 'white',
            border: '1px solid #00b8ff',
            margin: 'auto',
            position: 'absolute',
            top: 'calc(50vh - 100px)',
            left: 'calc(50vw - 250px)',
            textAlign: 'center'
          }}
        >
          {fail ? (
            <h2>Transaction rejected or failed.</h2>
          ) : succeedMsg !== '' ? (
            <>
              <h2>Transaction Successful!</h2> <h4>{`${succeedMsg.replace('%25', '%')}`}</h4>
            </>
          ) : (
            <h2>Transaction Successful!</h2>
          )}
          {succeed && succeedMsg !== '' && (
            <Button
              variant="contained"
              style={{
                textAlign: 'center',
                marginTop: '30px',
                height: '50px',
                minWidth: '150px',
                marginRight: '10px',
                borderRadius: '3em',
                background: '#00b8ff',
                border: 'none',
                boxShadow:
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
              }}
            >
              <a
                href={`https://twitter.com/intent/tweet?text=${succeedMsg.substring(
                  0,
                  succeedMsg.indexOf('https://') > 0
                    ? succeedMsg.indexOf('https://')
                    : succeedMsg.length
                )}&url=https://app.jaypeggers.com/${myRef}`}
                style={{
                  fontSize: '24px',
                  background: '#00b8ff',
                  color: 'white',
                  textDecoration: 'none'
                }}
                target="_blank"
                rel="noreferrer"
              >
                Share on Twitter!
              </a>
            </Button>
          )}
          <Button
            sx={{
              textAlign: 'center',
              marginTop: '30px',
              height: '50px',
              minWidth: '150px',
              fontSize: '24px',
              borderRadius: '3em',
              border: 'none',
              boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
            }}
            onClick={closeFail}
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </div>
    );
  }

  return <div />;
}

export default TxFail;
