// material
import { Card, Box } from '@mui/material';
import PropTypes from 'prop-types';

//
import AdvChart from '../../../components/advChart';

// ----------------------------------------------------------------------

export default function PriceChart(props) {
  const { innerWidth: width, innerHeight: height } = window;
  const { dataState, setDataState, units, name, dexPrice, sellPrice, buyPrice } = props;
  return (
    <Card
      sx={{
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '3em',
        marginBottom: '80px'
      }}
    >
      <Box sx={{ p: 3, pb: 1, textAlign: 'center' }} dir="ltr">
        <h3>
          {name}/{units} Price Chart
        </h3>
      </Box>
      <Box sx={{ p: 1, pb: 1, textAlign: 'center' }} dir="ltr">
        <div style={{ display: 'inline-block' }}>
          {'\u00A0'}
          {name} on Uniswap: ${dexPrice} |
        </div>
        <div style={{ display: 'inline-block' }}>
          {'\u00A0'}
          {name} burn price: ${sellPrice} |
        </div>
        <div style={{ display: 'inline-block' }}>
          {'\u00A0'}
          {name} mint price: ${buyPrice}
          {'\u00A0'}
          {width < 548 && '\u00A0'}
        </div>
      </Box>
      <Box sx={{ p: 3, pb: 1, pt: 1 }} dir="ltr">
        <AdvChart dataState={dataState} setDataState={setDataState} units={units} name={name} />
      </Box>
    </Card>
  );
}
PriceChart.propTypes = {
  dexPrice: PropTypes.string.isRequired,
  sellPrice: PropTypes.string.isRequired,
  buyPrice: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  dataState: PropTypes.number.isRequired,
  setDataState: PropTypes.func.isRequired
};
