// material
import { useCallback, useContext, useEffect, useState } from 'react';

import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  Box,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
  FormControl,
  Button,
  Link
} from '@mui/material';
import PropTypes from 'prop-types';
import LoadingButton from './LoadingButton';
import { Web3Context } from '../hooks/WalletContext';
// component

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  color: theme.palette.info.darker,
  backgroundColor: '#d5f3ff',
  fontFamily: 'monospace',
  width: '380px',
  maxWidth: '80vw',
  background: '#d5f3ff',
  marginBottom: '50px',
  borderRadius: '3em',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
}));
const Left = styled(Box)(({ theme }) => ({
  float: 'left',
  display: 'inline',
  marginLeft: '24px',
  color: theme.palette.grey[700],
  fontSize: '16px',
  maxWidth: '60%'
}));
const Right = styled(Box)(({ theme }) => ({
  float: 'right',
  display: 'inline',
  marginRight: '24px',
  fontWeight: 'bolder',
  color: theme.palette.grey[700]
}));
// ----------------------------------------------------------------------

export default function StakeInfoCard(props) {
  const {
    connected,
    pendingRequest,
    approveStake,
    stake,
    unstake,
    harvest,
    getLPTokenData,
    chainId,
    stakingApy
  } = useContext(Web3Context);

  const {
    title,
    reward,
    stakingAddress,
    pairAddress,
    rewardTokenAddress,
    tokenAddress,
    liqTokenAddress
  } = props.props;
  // console.log(`${pairAddress} ${stakingApy}`);
  const [pairData, setPairData] = useState({
    balance: '0',
    amountApproved: '0',
    staked: '0',
    rewards: '0',
    apy: '0',
    stakedUSDValue: '0',
    lpUSDValue: '0'
  });
  const { balance, amountApproved, staked, rewards, stakedUSDValue, lpUSDValue } = pairData;
  // console.log(amountApproved);
  const _approveStake = useCallback(() => {
    approveStake(pairAddress, stakingAddress);
  }, [pairAddress, stakingAddress, approveStake]);
  useEffect(() => {
    getLPTokenData(pairAddress, stakingAddress, rewardTokenAddress).then((data) => {
      setPairData(data);
    });
  }, [connected, pendingRequest, chainId]);

  const [valStake, setValStake] = useState('');
  const [valUnstake, setValUnstake] = useState('');

  useEffect(() => {
    setValUnstake(staked);
    setValStake(balance);
  }, [balance, staked, pendingRequest, connected, chainId]);

  const deposit = () => {
    stake(stakingAddress, valStake.toString());
  };
  const withdraw = () => {
    unstake(stakingAddress, valUnstake.toString());
  };
  const handleChangeValUnstake = async (event) => {
    setValUnstake(event.currentTarget.value);
  };
  const handleChangeValUnstakeMax = async (am) => {
    setValUnstake(am);
  };
  const handleChangeValStake = async (event) => {
    setValStake(event.currentTarget.value);
  };
  const handleChangeValStakeMax = async (am) => {
    setValStake(am);
  };
  return (
    <RootStyle>
      <Box sx={{ height: '50px' }}>
        <Box
          alt={title}
          width="50px"
          height="50px"
          component="img"
          src="/static/icons/usdc.png"
          sx={{
            float: 'left',
            display: 'inline',
            marginLeft: '20px',
            border: '1px solif black',
            borderRadius: '50%'
          }}
        />
        <Typography
          sx={{
            float: 'right',
            display: 'inline',
            marginRight: '20px',
            color: '#00b8ff',
            fontWeight: 'bold',
            fontSize: '32px',
            fontFamily: 'monospace'
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ height: '20px', marginTop: '25px' }}>
        <Left>APR:</Left>
        <Right>
          {rewardTokenAddress === 'native' ? stakingApy : Number(pairData.apy).toFixed(2)}%
        </Right>
      </Box>
      <Box sx={{ height: '20px', marginTop: '10px' }}>
        <Left>Earn:</Left>
        <Right>{reward}</Right>
      </Box>
      <Box sx={{ height: '20px', marginTop: '25px' }}>
        <Left sx={{ fontSize: '14px', fontWeight: 'bold' }}>{reward} EARNED</Left>
      </Box>
      <Box sx={{ height: '50px', marginTop: '10px' }}>
        <Left sx={{ fontSize: '24px', fontWeight: 'bold' }}>
          {Number(rewards) === 0
            ? Number(rewards).toFixed(2)
            : Number(rewards) < 0.00000001
            ? Number(rewards).toFixed(12)
            : Number(rewards) < 0.000001
            ? Number(rewards).toFixed(10)
            : Number(rewards) < 0.0001
            ? Number(rewards).toFixed(8)
            : Number(rewards) < 0.01
            ? Number(rewards).toFixed(6)
            : Number(rewards).toFixed(4)}
        </Left>
        <Right>
          <Button
            loading={pendingRequest}
            onClick={() => harvest(stakingAddress)}
            sx={{
              width: '-webkit-fill-available',
              p: '10px',
              fontSize: '20px',
              borderRadius: '3em',
              marginTop: '-9px !important',
              fontFamily: 'monospace',
              boxShadow: 'none'
            }}
            disabled={rewards <= 0}
            variant="contained"
          >
            Harvest
          </Button>
        </Right>
      </Box>
      <Box sx={{ height: '20px' }}>
        <Left sx={{ fontSize: '14px', fontWeight: 'bold' }}>{title} STAKED</Left>
      </Box>
      {connected ? (
        <>
          <Box sx={{ height: '55px', marginTop: '15px' }}>
            <Left sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              {' '}
              <FormControl
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  boxSizing: 'border-box',
                  borderRadius: '10em !important'
                }}
                variant="filled"
              >
                <OutlinedInput
                  sx={{
                    paddingRight: '80px',
                    boxSizing: 'border-box',
                    maxWidth: '215px',
                    overflow: 'hidden',
                    fontFamily: 'monospace'
                  }}
                  type="number"
                  onKeyPress={(event) => {
                    if (
                      event?.key === '-' ||
                      event?.key === '+' ||
                      event?.key === 'e' ||
                      event?.key === 'E'
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChangeValUnstake}
                  value={valUnstake}
                  placeholder={staked || '0.000'}
                />
                <FormHelperText sx={{ position: 'absolute', right: '82px', top: '33px' }}>
                  ${stakedUSDValue}
                </FormHelperText>

                <FormHelperText sx={{ textAlign: 'right', marginTop: '-42px' }}>
                  <Button
                    onClick={() => {
                      handleChangeValUnstakeMax(staked);
                    }}
                    variant="outlined"
                    size="small"
                    sx={{
                      fontFamily: 'monospace',
                      color: (theme) => theme.palette.grey[600],
                      borderColor: (theme) => theme.palette.grey[400]
                    }}
                  >
                    Max
                  </Button>
                </FormHelperText>
              </FormControl>
            </Left>
            <Right>
              <LoadingButton
                loading={pendingRequest}
                onClick={withdraw}
                sx={{
                  width: '-webkit-fill-available',
                  p: '10px',
                  fontSize: '20px',
                  borderRadius: '3em',
                  marginTop: '0 !important',
                  fontFamily: 'monospace'
                }}
                disabled={Number(valUnstake) <= 0 || valUnstake > staked}
                variant="contained"
              >
                Unstake
              </LoadingButton>
            </Right>
          </Box>
          <Box sx={{ height: '55px', marginTop: '15px' }}>
            <Left sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              {' '}
              <FormControl
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  boxSizing: 'border-box',
                  borderRadius: '10em !important'
                }}
                variant="filled"
              >
                <OutlinedInput
                  type="number"
                  sx={{
                    paddingRight: '80px',
                    boxSizing: 'border-box',
                    maxWidth: '215px',
                    overflow: 'hidden',
                    fontFamily: 'monospace'
                  }}
                  onKeyPress={(event) => {
                    if (
                      event?.key === '-' ||
                      event?.key === '+' ||
                      event?.key === 'e' ||
                      event?.key === 'E'
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChangeValStake}
                  value={valStake}
                  placeholder={balance || '0.000'}
                />
                <FormHelperText sx={{ position: 'absolute', right: '82px', top: '33px' }}>
                  ${lpUSDValue}
                </FormHelperText>
                <FormHelperText sx={{ textAlign: 'right', marginTop: '-42px' }}>
                  <Button
                    onClick={() => {
                      handleChangeValStakeMax(balance);
                    }}
                    variant="outlined"
                    size="small"
                    sx={{
                      fontFamily: 'monospace',
                      color: (theme) => theme.palette.grey[600],
                      borderColor: (theme) => theme.palette.grey[400]
                    }}
                  >
                    Max
                  </Button>
                </FormHelperText>
              </FormControl>
            </Left>
            <Right>
              <LoadingButton
                loading={pendingRequest}
                onClick={Number(amountApproved) >= Number(valStake) ? deposit : _approveStake}
                sx={{
                  p: '10px',
                  fontSize: '20px',
                  borderRadius: '3em',
                  width: '104px',
                  marginTop: '0 !important',
                  fontFamily: 'monospace'
                }}
                disabled={Number(valStake) <= 0 || valStake > balance}
                variant="contained"
              >
                {Number(amountApproved) >= Number(valStake) ? 'Stake' : 'Approve'}
              </LoadingButton>
            </Right>
          </Box>
          <Box style={{ marginTop: '15px', marginBottom: '-5px' }}>
            <Link
              style={{ fontWeight: 'bold' }}
              href={`https://app.uniswap.org/#/add/v2/${tokenAddress}/${liqTokenAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              Get LP Token on Uniswap
            </Link>
          </Box>
        </>
      ) : (
        <LoadingButton
          sx={{
            width: '-webkit-fill-available',
            p: '10px',
            fontSize: '20px',
            borderRadius: '3em',
            margin: '20px !important',
            marginBottom: '0 !important',
            fontFamily: 'monospace'
          }}
          variant="contained"
        />
      )}
    </RootStyle>
  );
}
StakeInfoCard.propTypes = {
  props: PropTypes.object.isRequired
};
