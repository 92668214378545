import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Tooltip
} from '@mui/material';
import WarningIcon from '@material-ui/icons/Warning';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.css';
import PropTypes from 'prop-types';
import { Web3Context } from '../../hooks/WalletContext';
import CollectionContainer from './CollectionContainer';
import AssetContainer from './AssetContainer';

function Vault(props) {
  const { succeed, vaultOpensea } = useContext(Web3Context);
  const { address, returnImages } = props;
  const [showFilters, setShowFilters] = useState(false);

  const [showAssets, setShowAssets] = useState({
    showAssets: false,
    collection: {}
  });

  const [state, setState] = useState({
    filters: [],
    hideFloorOver: false,
    hideOverThirty: false,
    hideOverOne: false,
    hideOverSeven: false,
    loading: true,
    sortBy: 5,
    textFilter: ''
  });
  const {
    hideFloorOver,
    hideOverThirty,
    filters,
    loading,
    hideOverOne,
    hideOverSeven,
    sortBy,
    textFilter
  } = state;

  const setTypeFilter = useCallback(
    (filter) => {
      if (filter?.currentTarget?.value !== '' || filter?.target?.value !== '') {
        const _val = filter?.currentTarget?.value || filter?.currentTarget?.value;
        // consolee.log(vaultOpensea);
        const filterFXN = (i) =>
          // consolee.log(`${i.name} - ${_val}`);
          i?.name?.toLowerCase().indexOf(_val.toLowerCase()) >= 0;
        setState((f) => ({
          ...f,
          textFilter: _val,
          filters: [...sorter(vaultOpensea?.filter(filterFXN) || '', f.sortBy)] || []
        }));
      } else {
        setState((f) => ({
          ...f,
          filters: [...(vaultOpensea || '')] || [],
          hideThirty: false,
          hideFloorOver: false,
          hideOverThirty: false,
          hideFloorUnder: false,
          hideOverOne: false,
          hideOverSeven: false,
          textFilter: ''
        }));
      }
    },
    [vaultOpensea]
  );

  const onChangeSortBy = useCallback(
    (sort) => {
      const _value = sort.target.value;

      // consolee.log(_value);
      setState((f) => ({
        ...f,
        filters: sorter(filters, _value),
        sortBy: _value
      }));
    },
    [filters]
  );
  const sorter = (filters, _sort) => {
    let _filters = [];
    switch (_sort) {
      case -1:
        _filters = filters;
        break;
      case 0:
        _filters = [...filters.sort((a, b) => a.name.localeCompare(b.name))];
        break;
      case 1:
        _filters = [...filters.sort((a, b) => b.name.localeCompare(a.name))];
        break;
      case 2:
        _filters = [...filters.sort((a, b) => (a.floor > b.floor ? -1 : 1))];
        break;
      case 3:
        _filters = [...filters.sort((a, b) => (a.thirty > b.thirty ? -1 : 1))];
        break;
      case 4:
        _filters = [...filters.sort((a, b) => (a.seven > b.seven ? -1 : 1))];
        break;
      case 5:
        _filters = [...filters.sort((a, b) => (a.one > b.one ? -1 : 1))];
        break;
      default:
      // code block
    }
    return _filters;
  };

  const getFilters = useCallback(() => {
    const filterFXN = (i) => {
      if (!hideOverThirty && !hideFloorOver && !hideOverOne && !hideOverSeven) return true;
      if (
        typeof i.thirty === 'number' &&
        typeof i.seven === 'number' &&
        typeof i.one === 'number' &&
        ((hideOverThirty && i.thirty > 1) ||
          (hideOverOne && i.one > 1) ||
          (hideOverSeven && i.seven > 1)) &&
        hideFloorOver &&
        i.floor > 0.01
      )
        return true;
      if (!hideFloorOver && hideOverThirty && typeof i.thirty === 'number' && i.thirty > 1)
        return true;
      if (!hideFloorOver && hideOverOne && typeof i.one === 'number' && i.one > 1) return true;
      if (!hideFloorOver && hideOverSeven && typeof i.seven === 'number' && i.seven > 1)
        return true;
      if (
        !hideOverThirty &&
        !hideOverSeven &&
        !hideOverOne &&
        hideFloorOver &&
        typeof i.floor === 'number' &&
        i.floor > 0.01
      )
        return true;
      return false;
    };

    const _filters = vaultOpensea.filter(filterFXN);
    setState((f) => ({
      ...f,
      filters: sorter(_filters, sortBy),
      loading: false
    }));
  }, [hideOverThirty, hideFloorOver, vaultOpensea, hideOverOne, hideOverSeven, sortBy]);

  useEffect(() => {
    if (succeed) {
      setState((s) => ({ ...s, filters: [], loading: true }));
    }
  }, [succeed]);

  useEffect(() => {
    if (vaultOpensea) {
      // // // console.log('Renderer - 5');
      getFilters();
    }
  }, [vaultOpensea, getFilters]);

  const onImageClick = useCallback((collection) => {
    setShowAssets({
      showAssets: true,
      collection
    });
  }, []);
  return showAssets.showAssets ? (
    <>
      <Button
        variant="contained"
        style={{ width: 100, border: 'none', borderRadius: '3em' }}
        onClick={() => {
          setShowAssets({
            showAssets: false
          });
        }}
      >
        Back
      </Button>
      <Box
        type="h1"
        sx={{
          position: 'absolute',
          width: 'calc(100% - 200px)',
          top: 0,
          left: 100,
          display: 'inline-block',
          textAlign: 'center',
          fontSize: ['18px', '2em', '2em', '2em'],
          zIndex: 0,
          fontWeight: 'bold'
        }}
      >
        {showAssets.collection.name}
      </Box>
      <AssetContainer
        collection={showAssets.collection}
        address={address}
        returnImages={returnImages}
      />
    </>
  ) : (
    <>
      <TextField
        className="searchBoxVault"
        label="Type to filter collections"
        variant="outlined"
        value={textFilter}
        onChange={setTypeFilter}
      />

      {!showFilters ? (
        <Button onClick={() => setShowFilters(true)}>show filters</Button>
      ) : (
        <div style={{ margin: '10px' }}>
          <FormControlLabel
            sx={{ verticalAlign: 'middle' }}
            label="Floor over 0.01 ETH"
            control={
              <Checkbox
                onChange={(a) => {
                  setState((s) => ({
                    ...s,
                    loading: true,
                    hideFloorOver: a.target.checked,
                    selectAll: false,
                    filters: []
                  }));
                }}
                checked={hideFloorOver}
                inputProps={{ 'aria-label': 'controlled' }}
                label=""
              />
            }
          />
          <FormControlLabel
            label="30 day volume over 1 ETH"
            sx={{ verticalAlign: 'middle' }}
            control={
              <Checkbox
                onChange={(a) => {
                  setState((s) => ({
                    ...s,
                    loading: true,
                    hideOverThirty: a.target.checked,
                    selectAll: false,
                    filters: []
                  }));
                }}
                checked={hideOverThirty}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label="7 day volume over 1 ETH"
            sx={{ verticalAlign: 'middle' }}
            control={
              <Checkbox
                onChange={(a) => {
                  setState((s) => ({
                    ...s,
                    loading: true,
                    hideOverSeven: a.target.checked,
                    selectAll: false,
                    filters: []
                  }));
                }}
                checked={hideOverSeven}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label="1 day volume over 1 ETH"
            sx={{ verticalAlign: 'middle' }}
            control={
              <Checkbox
                onChange={(a) => {
                  setState((s) => ({
                    ...s,
                    loading: true,
                    hideOverOne: a.target.checked,
                    selectAll: false,
                    filters: []
                  }));
                }}
                checked={hideOverOne}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControl sx={{ mt: '5px', minWidth: 160 }} margin="dense" size="small">
            <InputLabel
              sx={{ bgcolor: (theme) => theme.palette.grey[100], pl: '3px', pr: '3px' }}
              id="demo-select-small"
            >
              Sort By
            </InputLabel>
            <Select value={sortBy} onChange={onChangeSortBy}>
              <MenuItem value={0}>A to Z</MenuItem>
              <MenuItem value={1}>Z to A</MenuItem>
              <MenuItem value={2}>Highest Floor</MenuItem>
              <MenuItem value={3}>Highest 30D Vol</MenuItem>
              <MenuItem value={4}>Highest 7D Vol</MenuItem>
              <MenuItem value={5}>Highest 1D Vol</MenuItem>
            </Select>
          </FormControl>

          <Button sx={{ m: '7.5px' }} onClick={() => setShowFilters(false)}>
            hide filters
          </Button>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          right: '30px',
          top: '3px'
        }}
      >
        <Tooltip title="Caution: Floor and volume data is from Opensea and NFTbank and may not be accurate">
          <IconButton aria-label="Caution: data is from Opensea and Alchemy and may not be accurate">
            <WarningIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Box
        type="div"
        id="scrollableDivVault"
        sx={{
          height: [
            'calc(100vh - 325px)',
            'calc(100vh - 325px)',
            'calc(100vh - 345px)',
            'calc(100vh - 345px)'
          ],
          overflow: 'auto',
          display: 'flex'
        }}
      >
        {address !== '' ? (
          <InfiniteScroll
            scrollThreshold="1000px"
            style={{ minWidth: '100%', paddingTop: 3, overflowX: 'hidden' }}
            scrollableTarget="scrollableDivVault"
            dataLength={filters?.length}
            loader={
              <div style={{ textAlign: 'center', minWidth: '100%' }}>
                <h4>Loading...</h4>
              </div>
            }
            endMessage={
              <div style={{ textAlign: 'center', minWidth: '100%' }}>
                <b>That's all there is to see!</b>
              </div>
            }
            hasMore={loading}
          >
            {filters?.map((img) => (
              <CollectionContainer key={img.key} onImageClick={onImageClick} img={img} />
            ))}
          </InfiniteScroll>
        ) : (
          <h3 style={{ width: '100%', textAlign: 'center' }}>Connect wallet to load NFTs</h3>
        )}
      </Box>
    </>
  );
}
Vault.propTypes = {
  returnImages: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired
};

export default Vault;
