import { useRef } from 'react';
import { alpha } from '@mui/material/styles';
// material
import { IconButton } from '@mui/material';

export default function LanguagePopover() {
  const anchorRef = useRef(null);

  const handleOpen = () => {
    window.open('https://twitter.com/Jaypeggerz');
  };

  return (
    <IconButton
      ref={anchorRef}
      onClick={handleOpen}
      sx={{
        padding: '8px',
        width: 44,
        height: 44,
        border: '1px solid #00b8ff',
        margin: 'auto',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        backgroundImage: () =>
          `linear-gradient(135deg, ${alpha('#fff', 0)} 0%, ${alpha('#fff', 0.5)} 100%)`
      }}
    >
      <img src="/static/icons/twitter_icon.svg" alt="twitter" />
    </IconButton>
  );
}
