// material
import { useContext } from 'react';

import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Web3Context } from '../../../hooks/WalletContext';
import Iconify from '../../../components/Iconify';

// utils

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.grey[600],
  backgroundColor: '#d5f3ff'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  border: '1px solid',
  borderColor: theme.palette.grey[600],
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.grey[600]
}));

// ----------------------------------------------------------------------

export default function MyJay(props) {
  return (
    <RootStyle
      sx={{
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '3em'
      }}
    >
      <IconWrapperStyle>
        <Iconify icon="mdi:egg-outline" width={30} height={30} />
      </IconWrapperStyle>
      <Typography variant="h3">{Math.round(Number(props.balance) * 100) / 100}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        My {props.name}
      </Typography>
    </RootStyle>
  );
}
MyJay.propTypes = {
  balance: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
