// material
import { useContext, useEffect, useState } from 'react';

import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  Box,
  InputAdornment,
  FormHelperText,
  Input,
  FormControl,
  Button,
  Link
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { set } from 'lodash';
import LoadingButton from '../../../components/LoadingButton';
import { Web3Context } from '../../../hooks/WalletContext';
// component
import Iconify from '../../../components/Iconify';
import { JAY_CONTRACT_ } from '../../../hooks/constants/contracts';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: '#d5f3ff',
  fontFamily: 'monospace',
  background: '#d5f3ff',
  borderRadius: '3em',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: '-5px',
  marginTop: '-5px',
  opacity: 0.8,
  color: '#00b8ff'
}));

// ----------------------------------------------------------------------
const PAIRS = [
  {
    assets: ['ETH', 'JAY'],
    type: 'BUY',
    contract: JAY_CONTRACT_[1].address,
    pair: 'native',
    native: true,
    liqPair: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
  },
  {
    assets: ['JAY', 'ETH'],
    type: 'SELL',
    contract: JAY_CONTRACT_[1].address,
    pair: 'native',
    liqPair: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
  },
  {
    assets: ['USDC', 'jUSDC'],
    type: 'BUY',
    contract: '0xca9f9671765F8D1A7e19ae2639E01FFF730f0D9B',
    pair: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    liqPair: 'ETH'
  },
  {
    assets: ['jUSDC', 'USDC'],
    type: 'SELL',
    contract: '0xca9f9671765F8D1A7e19ae2639E01FFF730f0D9B',
    pair: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    liqPair: 'ETH'
  }
];
export default function BuySwaper() {
  const {
    getEthToJay,
    buy,
    sell,
    pendingRequest,
    getTokenData,
    connected,
    approveERC20,
    eventType,
    resetEventType
  } = useContext(Web3Context);
  const [token, setToken] = useState({});
  // console.log(Number(token?.amountApproved));
  const [amount, setAmount] = useState(0);
  const [val, setVal] = useState('');
  const [asset, setAsset] = useState(PAIRS[2]);
  const approve = () => {
    approveERC20(asset?.pair, asset?.contract);
  };
  const deposit = () => {
    if (asset.type === 'BUY') buy(asset.contract, asset.pair, val, asset?.native);
    if (asset.type === 'SELL') sell(asset.contract, val);
  };
  useEffect(() => {
    if (eventType === 'SWAP') {
      setVal(0);
      setAmount(0);
      getTokenData(asset.contract, asset.pair).then((_data) => {
        setToken(_data);
      });
      resetEventType();
    }
    if (eventType === 'APPROVE') {
      getTokenData(asset.contract, asset.pair).then((_data) => {
        setToken(_data);
      });
      resetEventType();
    }
  }, [asset, getTokenData, eventType, resetEventType]);
  useEffect(() => {
    setVal(0);
    setAmount(0);
    getTokenData(asset.contract, asset.pair).then((_data) => {
      setToken(_data);
    });
  }, [connected, asset, getTokenData]);
  // console.log(1111);
  const handleChange = async (event) => {
    setVal(event.currentTarget.value);
    setAmount(await getEthToJay(asset.type, asset.contract, asset.pair, event.currentTarget.value));
  };
  const handleChangeMax = async (am) => {
    setVal(am);
    setAmount(await getEthToJay(asset.type, asset.contract, asset.pair, am));
  };
  const handleChangeAsset = (event) => {
    setAsset(event.target.value);
  };
  useEffect(() => {
    setAsset(PAIRS[2]);
  }, []);
  return (
    <>
      <RootStyle
        sx={{
          maxWidth: '450px',
          margin: 'auto',
          marginBottom: '50px'
        }}
      >
        <Typography sx={{ mt: '-25px', mb: '10px', color: '#00b8ff' }} variant="h3">
          SWAP
        </Typography>
        <Box
          sx={{
            pl: 0,
            pr: 0,
            ml: '30px',
            mr: '30px'
          }}
        >
          <FormControl sx={{ m: 1, mb: 0, width: '35ch', maxWidth: '90%' }} variant="filled">
            <Input
              type="number"
              onChange={handleChange}
              value={val}
              placeholder="0"
              sx={{ fontSize: '18px' }}
              onKeyPress={(event) => {
                if (
                  event?.key === '-' ||
                  event?.key === '+' ||
                  event?.key === 'e' ||
                  event?.key === 'E'
                ) {
                  event.preventDefault();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <FormControl id="formcontrol" sx={{ minWidth: 80, marginRight: '-10px' }}>
                    <Select
                      sx={{ border: 'none' }}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={asset}
                      onChange={handleChangeAsset}
                      autoWidth
                    >
                      {PAIRS.map((p) => (
                        <MenuItem value={p}>{p.assets[0]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputAdornment>
              }
            />
            <FormHelperText sx={{ textAlign: 'right', margin: '5px 0' }}>
              <Typography display="inline" variant="subtitle2" sx={{ marginRight: '0px' }}>
                Balance:{' '}
                {Number(
                  asset.type === 'SELL' ? token?.balance || '0' : token?.balancePair || '0'
                ).toFixed(6)}{' '}
              </Typography>
              {Number(val) <
                Number(
                  asset.type === 'SELL' ? token?.balance || '0' : token?.balancePair || '0'
                ) && (
                <Box
                  onClick={() => {
                    handleChangeMax(
                      asset.type === 'SELL' ? token?.balance || '0' : token?.balancePair || '0'
                    );
                  }}
                  variant="outlined"
                  size="small"
                  sx={{
                    display: 'inline',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: (theme) => theme.palette.primary.main,
                    borderColor: 'rgba(134, 142, 150, 0.32)',
                    cursor: 'pointer',
                    '&:hover': {
                      color: (theme) => theme.palette.primary.light
                    }
                  }}
                >
                  Max
                </Box>
              )}
            </FormHelperText>
          </FormControl>
          <IconWrapperStyle>
            <Iconify icon="mdi:arrow-down" width={30} height={30} />
          </IconWrapperStyle>
          <FormControl sx={{ m: 0, width: '35ch', maxWidth: '90%' }} variant="filled">
            <Input
              sx={{
                fontSize: '18px'
              }}
              type="number"
              value={amount}
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <Typography variant="subtitle1">{asset.assets[1]}</Typography>
                </InputAdornment>
              }
            />
            <FormHelperText sx={{ textAlign: 'right', margin: '5px 0' }}>
              <Typography
                display="inline"
                variant="subtitle2"
                sx={{ verticalAlign: 'sub', marginRight: '0px' }}
              >
                Balance:{' '}
                {Number(
                  asset.type === 'BUY' ? token?.balance || '0' : token?.balancePair || '0'
                ).toFixed(6)}{' '}
              </Typography>
            </FormHelperText>
          </FormControl>
        </Box>
        <LoadingButton
          loading={pendingRequest}
          onClick={asset.type === 'BUY' && val > Number(token?.amountApproved) ? approve : deposit}
          sx={{
            width: '-webkit-fill-available',
            mt: '30px',
            ml: '30px',
            mr: '30px',
            mb: '-15px',
            p: '10px',
            fontSize: '24px',
            boxShadow: '0 !important'
          }}
          disabled={
            val === '' ||
            val >
              (asset.type === 'SELL'
                ? Number(token?.balance) || 0
                : Number(token?.balancePair) || 0)
          }
          variant="contained"
        >
          {asset.type === 'BUY' && val > Number(token?.amountApproved)
            ? `Approve ${asset.type === 'BUY' ? asset.assets[0] : asset.assets[1]}`
            : val >
              (asset.type === 'SELL'
                ? Number(token?.balance) || 0
                : Number(token?.balancePair) || 0)
            ? 'more ETH required'
            : `${asset.type} ${asset.type === 'BUY' ? asset.assets[1] : asset.assets[0]}`}
        </LoadingButton>
      </RootStyle>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          paddingLeft: 25,
          paddingRight: 25,
          marginBottom: 100
        }}
      >
        <Link
          style={{ fontWeight: 'bold', fontSize: 20 }}
          href={`https://app.uniswap.org/#/swap?inputCurrency=${asset.liqPair}&outputCurrency=${asset.contract}`}
          target="_blank"
          rel="noreferrer"
        >
          Buy/Sell {asset.type === 'BUY' ? asset.assets[1] : asset.assets[0]} on Uniswap
        </Link>
      </div>
    </>
  );
}
