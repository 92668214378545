// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//
import { SWRConfig } from 'swr';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem('cashe-jay') || '[]'));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('unload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem('cashe-jay', appCache);
  });

  // We still use the map for write & read for performance.
  return map;
}

// ----------------------------------------------------------------------

ReactDOM.render(
  <SWRConfig value={{ provider: localStorageProvider }}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </SWRConfig>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(\\ // // // // console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
