import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import Swap from './pages/Swap';
import DashboardApp from './pages/DashboardApp';
import Buy from './pages/Buy';
import BuyNFT from './pages/BuyNFT';
import StakeAll from './pages/StakeAll';
import Referral from './pages/Referral';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'sellNFTs', element: <Buy /> },
        { path: 'swapJay', element: <Swap /> },
        { path: 'buyNFTs', element: <BuyNFT /> },
        { path: 'stake', element: <StakeAll /> },
        { path: 'stakeAll', element: <StakeAll /> },
        { path: 'referral', element: <Referral /> },
        { path: '/', element: <Navigate to="/dashboard" /> }
      ]
    },
    {
      path: '',
      element: <DashboardLayout />,
      children: [{ path: '', element: <Navigate to="/dashboard" /> }]
    },
    {
      path: '*',
      element: <DashboardLayout />,
      children: [{ path: '*', element: <Buy /> }]
    }
  ]);
}
