import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
// import HighchartsReact from "./HighchartsReact.min.js";
import HighchartsReact from 'highcharts-react-official';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Web3Context } from '../hooks/WalletContext';
import Live from './Live';

export default function AdvChart(props) {
  const { priceData, priceDataUSDC } = useContext(Web3Context);
  const [dataGrouping, setDataGrouping] = useState({
    units: [['minute', [30]]],
    type: 'minute'
  });
  // console.log(props);
  const { dataState, setDataState } = props;

  useEffect(() => {
    // // console.log(Highcharts.charts.length);
    if (priceData.length > 0 && priceDataUSDC.length > 0 && Highcharts.charts.length > 0) {
      Highcharts.each(Highcharts.charts, (chart) => {
        // It is null while updating
        if (chart && chart.xAxis[0].max === chart.xAxis[0].userMax) {
          // // console.log('we run');
          chart.xAxis[0].setExtremes(
            chart.xAxis[0].userMin,
            new Date().getTime() + 60000,
            undefined,
            false,
            {
              trigger: 'syncExtremes'
            }
          );
        }
      });
    }
  }, [priceData, priceDataUSDC, Highcharts]);

  const [configPrice, setConfigPrice] = useState({
    yAxis: [
      {
        offset: 20,
        labels: {
          formatter(value) {
            return `${value?.value?.toFixed(6) || '?'} ${props.units}`;
          },
          x: -15,
          style: {
            color: '#000',
            position: 'absolute'
          },
          align: 'left'
        }
      }
    ],
    tooltip: {
      shared: true
    },
    plotOptions: {
      series: {
        showInNavigator: true,
        connectNulls: true
      },
      candlestick: {
        downColor: 'red',
        upColor: 'green'
      }
    },

    credits: {
      enabled: false
    },

    legend: {
      enabled: false
    },
    chart: {
      height: 600
    },
    rangeSelector: {
      enabled: true,
      allButtonsEnabled: true,
      inputEnabled: true,
      buttons: [
        {
          type: 'minute',
          count: 100,
          text: '1m',
          events: {
            click: () => setDataGrouping({ units: [['minute', [1]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'minute',
          count: 200,
          events: {
            click: () => setDataGrouping({ units: [['minute', [5]]], forced: true, groupAll: true })
          },
          text: '5m'
        },
        {
          type: 'minute',
          count: 1000,
          text: '30m',
          events: {
            click: () =>
              setDataGrouping({ units: [['minute', [30]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'hour',
          count: 24,
          text: '1h',
          events: {
            click: () => setDataGrouping({ units: [['hour', [1]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'hour',
          count: 48,
          text: '4h',
          events: {
            click: () => setDataGrouping({ units: [['hour', [4]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'day',
          count: 30,
          text: '1d',
          events: {
            click: () => setDataGrouping({ units: [['day', [1]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'day',
          count: 30,
          text: '7d',
          events: {
            click: () => setDataGrouping({ units: [['week', [1]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'day',
          count: 30,
          text: '14d',
          events: {
            click: () => setDataGrouping({ units: [['week', [2]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'month',
          count: 24,
          text: '1m',
          events: {
            click: () => setDataGrouping({ units: [['month', [1]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'month',
          count: 24,
          text: '3m',
          events: {
            click: () => setDataGrouping({ units: [['month', [3]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'month',
          count: 24,
          text: '6m',
          events: {
            click: () => setDataGrouping({ units: [['month', [6]]], forced: true, groupAll: true })
          }
        },
        {
          type: 'all',
          count: 1,
          text: 'All',
          dataGrouping: {
            units: [['hour', [1]]],
            approximation: 'ohlc'
          },
          lastVisablePrice: {
            enabled: true
          }
        }
      ]
    },

    series: [
      {
        type: 'candlestick',
        name: 'Price',
        data: dataState === 0 ? priceData : priceDataUSDC,
        tooltip: {
          valueDecimals: 8
        },
        dataGrouping
      }
    ]
  });
  const setExtremes = useCallback(() => {
    if (Highcharts.charts.length > 0) {
      Highcharts.each(Highcharts.charts, (chart) => {
        // It is null while updating
        if (chart && chart.xAxis[0].max === chart.xAxis[0].userMax) {
          // // console.log('we run');
          chart.xAxis[0].setExtremes(
            chart.xAxis[0].userMin,
            new Date().getTime() + 60000,
            undefined,
            false,
            {
              trigger: 'syncExtremes'
            }
          );
        }
      });
    }
  }, Highcharts?.charts);
  useEffect(() => {
    setTimeout(() => {
      setExtremes();
      setConfigPrice({
        xAxis: { max: new Date().getTime() + 60000, type: 'datetime', ordinal: true }
      });
    }, 60000);
  }, [configPrice, Highcharts]);
  useEffect(() => {
    if (priceData.length > 0 || priceDataUSDC.length > 0) {
      setConfigPrice({
        series: [{ data: dataState === 0 ? priceData : priceDataUSDC }],
        xAxis: { max: new Date().getTime() + 60000, type: 'datetime', ordinal: true },
        yAxis: [
          {
            offset: 20,
            labels: {
              formatter(value) {
                return `${value?.value?.toFixed(6) || '?'} ${props.units}`;
              },
              x: -15,
              style: {
                color: '#000',
                position: 'absolute'
              },
              align: 'left'
            }
          }
        ]
      });
    }
  }, [priceData, priceDataUSDC, dataState]);
  useEffect(() => {
    setConfigPrice({
      series: [{ dataGrouping }],
      xAxis: { max: new Date().getTime() + 60000, type: 'datetime', ordinal: true }
    });
  }, [dataGrouping]);

  return (
    <div style={{ minHeight: 600 }}>
      <Live />
      <Button
        style={{
          background: dataState === 0 ? '#d5f3ff' : ''
        }}
        onClick={() => setDataState(0)}
      >
        JAY
      </Button>
      <Button
        style={{
          marginLeft: '10px',
          background: dataState === 0 ? '' : '#d5f3ff'
        }}
        onClick={() => setDataState(1)}
      >
        jUSDC
      </Button>
      <HighchartsReact
        constructorType="stockChart"
        highcharts={Highcharts}
        allowChartUpdate
        options={configPrice}
      />
    </div>
  );
}
AdvChart.propTypes = {
  name: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  dataState: PropTypes.number.isRequired,
  setDataState: PropTypes.func.isRequired
};
