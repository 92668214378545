// material
import { Grid, Container } from '@mui/material';
// components
import { useState, useContext, useEffect } from 'react';
import Page from '../components/Page';
import { JayApy, MyJay, PriceChart, Sold, StakingApy } from '../sections/@dashboard/app';
import { Web3Context } from '../hooks/WalletContext';
import { JAY_CONTRACT_, LP_STAKING_, LP_TOKEN_ } from '../hooks/constants/contracts';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { connected, pendingRequest, chainId, getTokenDataDash } = useContext(Web3Context);
  const [dataState, setDataState] = useState(0);
  const pairs = [
    {
      name: 'JAY',
      title: 'JAY/USDC LP',
      reward: 'ETH',
      stakingAddress: LP_STAKING_[chainId].address,
      pairAddress: LP_TOKEN_[chainId].address,
      rewardTokenAddress: 'native',
      tokenAddress: JAY_CONTRACT_[chainId].address,
      liqTokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      date: '4/20/2023'
    },
    {
      name: 'jUSDC',
      title: 'jUSDC/ETH LP',
      reward: 'USDC',
      stakingAddress: '0xF70C2657C4135C2cE81977C32F7bC1E012A7226d',
      pairAddress: '0x893Be9aca9bE32a9bCE005D034E4C1facd4709Ac',
      rewardTokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      tokenAddress: '0xca9f9671765F8D1A7e19ae2639E01FFF730f0D9B',
      liqTokenAddress: JAY_CONTRACT_[chainId].address,
      date: '8/3/2023'
    }
  ];
  const [pairData, setPairData] = useState({
    balance: '0',
    vaultBal: '0',
    apy: '0',
    sellPrice: '0',
    dexPrice: '0',
    buyPrice: '0'
  });
  const { apy, balance, vaultBal, sellPrice, dexPrice, buyPrice } = pairData;

  useEffect(() => {
    getTokenDataDash(
      pairs[dataState].tokenAddress,
      pairs[dataState].pairAddress,
      pairs[dataState].stakingAddress,
      pairs[dataState].rewardTokenAddress
    ).then((data) => {
      // console.log(data);
      setPairData((s) => ({
        ...s,
        balance: data.balance,
        apy: data.apy,
        vaultBal: data.vaultBal,
        sellPrice: data.sellPrice,
        dexPrice: data.dexPrice,
        buyPrice: data.buyPrice
      }));
    });
  }, [connected, pendingRequest, chainId, dataState, getTokenDataDash]);

  return (
    <Page title="Jaypeggers | Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={3}>
            <MyJay balance={balance} name={pairs[dataState].name} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Sold vaultBal={vaultBal} units={pairs[dataState].reward} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StakingApy apy={apy} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <JayApy
              name={pairs[dataState].name}
              date={pairs[dataState].date}
              tokenAddress={pairs[dataState].tokenAddress}
              tokenCounterpartAddress={pairs[dataState].rewardTokenAddress}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <PriceChart
              sellPrice={sellPrice}
              dexPrice={dexPrice}
              buyPrice={buyPrice}
              dataState={dataState}
              setDataState={setDataState}
              units={pairs[dataState].reward}
              name={pairs[dataState].name}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
