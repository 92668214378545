import useSWR from 'swr';
import { ethers } from 'ethers';
import { REF_MART_ as REF_MART } from './constants/contracts';

const _provider = new ethers.providers.EtherscanProvider(
  'homestead',
  'A7257M9X55EPEKRDUNV1Q4JH65YM7NKHIT'
);

const refMartContract = new ethers.Contract(REF_MART[1].address, REF_MART[1].abi, _provider);
const decoded = (val) => {
  try {
    const decode = ethers.utils.defaultAbiCoder.decode(
      ['string'],
      ethers.utils.hexDataSlice(val, 4)
    );
    return decode[0].length > 0 ? decode[0] : null;
  } catch {
    return null;
  }
};
const getLeaderBoard = async (h) =>
  Promise?.all(
    h?.map(async (address) => {
      const val = await refMartContract.getRefEarnedTotal(address.from);
      const name = await refMartContract.getMyRef(address.from);
      return { address: address.from, value: ethers.utils.formatEther(val), name };
    })
  );

export default function useLeaderboard() {
  const history = useSWR(
    'getHistory',
    () => {
      console.log('Data Revalidate');
      return _provider
        .getHistory(
          REF_MART[1].address,
          history?.data
            ? Number(history?.data[history.data.length - 1]?.blockNumber) + 10
            : '18685925'
        )
        .then((_data) => {
          if (history?.data) {
            return [...history.data, ..._data];
          }
          return [..._data];
        });
    },
    { refreshInterval: 5000 }
  );

  const _history = history?.data
    ?.filter((f) => Number(ethers.utils.formatEther(f.value)) === 0)
    .filter((f) => typeof decoded(f.data) === 'string');

  const leaderBoard = useSWR('leaderboard', () =>
    (history?.data ? getLeaderBoard(_history) : null).then((l) =>
      l.sort((a, b) => (Number(a.value) < Number(b.value) ? 1 : -1))
    )
  );
  if (!leaderBoard)
    return {
      data: [],
      isError: false,
      isLoading: true
    };
  return {
    data: leaderBoard?.data,
    isError: !!leaderBoard.error,
    isLoading: !leaderBoard.data && !leaderBoard.error
  };
}
