import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Banner } from './styles';

function UDBanner() {
  const [loc, setLoc] = useState(800);
  /*
  const ban = useCallback(() => {
    if (loc > 0) {
      setLoc(loc - 1);
    } else {
      setLoc(850);
    }
  }, [loc]);

  useEffect(() => {
    if (window.innerWidth < 450) setTimeout(ban, 10);
    else setTimeout(ban, 30);
  }, [loc, ban]); */

  return (
    <Banner style={{ paddingLeft: `${loc}px` }}>
      {/* <span style={{ marginLeft: '-750px' }}>
        The top 5 NFT harvesters before 11/4 will win a $100 Unstoppable Domains Credit!
      </span>
      <span style={{ marginLeft: '150px' }}>
        The top 5 NFT harvesters before 11/4 will win a $100 Unstoppable Domains Credit!
      </span>
      <span style={{ marginLeft: '150px' }}>
        The top 5 NFT harvesters before 11/4 will win a $100 Unstoppable Domains Credit!
      </span>
      <span style={{ marginLeft: '150px' }}>
        The top 5 NFT harvesters before 11/4 will win a $100 Unstoppable Domains Credit!
      </span>
      <span style={{ marginLeft: '150px' }}>
        The top 5 NFT harvesters before 11/4 will win a $100 Unstoppable Domains Credit!
      </span>
      <span style={{ marginLeft: '150px' }}>
        The top 5 NFT harvesters before 11/4 will win a $100 Unstoppable Domains Credit!
      </span>
      <span style={{ marginLeft: '150px' }}>
        The top 5 NFT harvesters before 11/4 will win a $100 Unstoppable Domains Credit!
  </span> */}
    </Banner>
  );
}

export default memo(UDBanner);
