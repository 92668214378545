// material
import { useContext } from 'react';

import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// utils

//
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.grey[600],
  paddingBottom: '16px',
  backgroundColor: '#ffedfc'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.palette.grey[600],
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.grey[600]
}));

// ----------------------------------------------------------------------

export default function Sold(props) {
  return (
    <RootStyle
      sx={{
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '3em'
      }}
    >
      <IconWrapperStyle>
        <Iconify
          icon="streamline:money-cash-bag-dollar-bag-payment-cash-money-finance"
          width={26}
          height={26}
        />
      </IconWrapperStyle>
      <Typography variant="h3">
        {Number(props.vaultBal)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
        {props.units}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72, marginBottom: '25px' }}>
        Total Backing
      </Typography>
    </RootStyle>
  );
}
Sold.propTypes = {
  vaultBal: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired
};
