import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useConnectWallet } from '@web3-onboard/react';

const styles = () => ({
  button: {
    marginTop: '25px',
    zIndex: '6',
    padding: '10px',
    fontSize: '20px',
    borderRadius: '3em',
    fontFamily: 'monospace',
    border: 0,
    boxShadow: 'none'
  }
});

function LoadingButton(props) {
  const { classes, loading, ...other } = props;
  const [{ wallet }, connect] = useConnectWallet();
  if (!wallet) {
    return (
      <Button
        className={classes.button}
        {...other}
        disabled={false}
        onClick={async () => {
          await connect();
        }}
      >
        Connect Wallet
      </Button>
    );
  }
  if (loading) {
    return (
      <Button className={classes.button} disabled {...other}>
        <Box
          component="img"
          src="/static/loader.png"
          sx={{
            width: 42,
            height: 42,
            p: '4px',
            animation: 'rotation 4s infinite linear',
            border: '3px solid',
            borderRadius: '50%',
            margin: 'auto',
            borderColor: (theme) => theme.palette.primary.dark
          }}
        />
      </Button>
    );
  }
  return <Button className={classes.button} {...other} />;
}

LoadingButton.defaultProps = {
  loading: false
};

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool
};

export default withStyles(styles)(LoadingButton);
