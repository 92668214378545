import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const GREY = {
  0: '#FFFFFF',
  100: '#f8f9fa',
  200: '#e9ebed',
  300: '#dee2e6',
  400: '#adb5bd',
  500: '#868e96',
  600: '#495057',
  700: '#343a40',
  800: '#21252b',
  500_8: alpha('#868e96', 0.08),
  500_12: alpha('#868e96', 0.12),
  500_16: alpha('#868e96', 0.16),
  500_24: alpha('#868e96', 0.5),
  500_32: alpha('#868e96', 0.32),
  500_48: alpha('#868e96', 0.48),
  500_56: alpha('#868e96', 0.56),
  500_80: alpha('#868e96', 0.8)
};

const PRIMARY = {
  lighter: '#99e2ff',
  light: '#4dccff',
  main: '#00b8ff',
  dark: '#0080b3',
  darker: '#005b80',
  contrastText: '#fff'
};
const SECONDARY = {
  lighter: '#ff8155',
  light: '#ff8155',
  main: '#ff8155',
  dark: '#ff8155',
  darker: '#ff8155',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#ffc7b3',
  light: '#ffa280',
  main: '#ff8155',
  dark: '#ff6933',
  darker: '#e63d00',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#EFC6B8',
  light: '#EFC6B8',
  main: '#EFC6B8',
  dark: '#EFC6B8',
  darker: '#EFC6B8',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#eadbc8',
  light: '#dbc3a3',
  main: '#d2b48c',
  dark: '#bf935a',
  darker: '#815e32',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#afc0d0',
  light: '#7e9ab4',
  main: '#5a7a98',
  dark: '#425a70',
  darker: '#263340',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: GREY[0], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
