import { Container, Typography, TextField, Button, Box, Card } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState, useContext, useEffect } from 'react';
import { relative } from 'path';
import Page from '../components/Page';
import { Web3Context } from '../hooks/WalletContext';
//
import Iconify from '../components/Iconify';
import characters from '../imgs/birbsreflink.png';
import bg from '../imgs/moneyreflink.png';
import Leaderboard from '../components/Leaderboard';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  fontWeight: 'bold',
  borderRadius: '50%',
  border: '3px solid',
  borderColor: theme.palette.grey[800],
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.grey[800]
}));

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  color: theme.palette.grey[800],
  backgroundColor: '#d5f3ff',
  height: '250px',
  maxWidth: '400px',
  flex: '1 1 400px;',
  margin: 'auto',
  padding: '20px',
  borderRadius: '20px',
  marginBottom: '40px'
}));

const styles = {
  container: {
    textAlign: 'left'
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '40px',
    position: 'relative',
    zIndex: '2',
    background: 'white'
  },
  content: {
    paddingLeft: '50px',
    paddingRight: '50px',
    fontSize: '18px',
    marginBottom: '16px',
    position: 'relative',
    zIndex: '2',
    background: 'white'
  },
  referralLink: {
    marginTop: '0px',
    marginBottom: '40px',
    position: 'relative',
    zIndex: '3',
    background: 'white',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    borderRadius: '3em',
    border: '1px solid #00B8FF',
    fontSize: '18px'
  },
  box: {
    borderRadius: '3em',
    padding: '8px',
    paddingBottom: '3px',
    paddingTop: '15px',
    border: '1px solid #00B8FF',
    marginBottom: '40px',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    position: 'relative',
    zIndex: '2',
    background: 'white',
    minHeight: '56px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  boxContent: {
    paddingLeft: '20px',
    paddingRight: '20px',
    fontSize: 'calc(.4vw + 10px)',
    width: '50%',
    display: 'inline-block',
    position: 'relative',
    zIndex: '2',
    flex: '1 0 400px;',
    flexBasis: 'content'
  }
};

export default function Referral() {
  const { getMyReferralLink, myRef, isValidRef, claimRef, connected, handleConnect, myFees } =
    useContext(Web3Context);

  const [referralLink, setReferralLink] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleIsValid = async (_referralLink) => {
    if (!(await isValidRef(_referralLink))) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };
  const handleClaim = () => {
    claimRef(referralLink);
  };
  useEffect(() => {
    if (connected) getMyReferralLink();
  }, [connected]);
  useEffect(() => {
    handleIsValid(referralLink);
  }, [referralLink]);
  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(`https://app.jaypeggers.com/${myRef}`);
  };
  return (
    <Page
      title="Jaypeggers | Referral"
      sx={{
        width: '100%',
        margin: '-80px 0px 0px 0!important',
        position: 'relative',
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'top' /* Center the image */,
        backgroundSize: 'contain' /* Resize the background image to cover the entire container */,
        zIndex: '0',
        paddingTop: '80px',
        height: 'calc(100vh - 35px)'
      }}
    >
      <Container sx={styles.container}>
        {/* Show current referral link if available */}
        {myRef && (
          <Box variant="div" sx={styles.box}>
            {/* Show total referrals earned */}
            <Typography variant="body1" sx={styles.boxContent}>
              Total Earned: {myFees} ETH
            </Typography>
            <Typography className="textLeft" variant="body1" sx={{ ...styles.boxContent }}>
              My Ref Link: app.jaypeggers.com/{myRef}{' '}
              <div
                style={{ verticalAlign: 'text-top', display: 'inline-block', cursor: 'pointer' }}
              >
                <Iconify
                  icon="solar:copy-line-duotone"
                  width={20}
                  height={20}
                  onClick={handleCopyClick}
                />
              </div>
            </Typography>
          </Box>
        )}

        {/* Input field for claiming referral link */}
        <img
          src={characters}
          alt="birb characters"
          style={{
            padding: '0px 15%',
            zIndex: '9',
            position: 'relative'
          }}
        />
        <TextField
          label="Claim Referral Link"
          variant="outlined"
          fullWidth
          value={referralLink}
          onChange={(e) => setReferralLink(e.target.value)}
          sx={styles.referralLink}
        />

        {/* Button to claim referral link */}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div
            style={{
              margin: 'auto',
              borderRadius: '18px',
              background: 'white',
              display: 'inline-block'
            }}
          >
            {connected ? (
              <Button
                variant="contained"
                onClick={handleClaim}
                disabled={!isValid}
                style={{
                  margin: 'auto',
                  padding: '10px 20px',
                  fontSize: '18px',
                  display: 'block',
                  position: 'relative',
                  zIndex: '2',
                  border: '1px solid #00B8FF',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                }}
              >
                {isValid ? 'Claim Referral Link' : 'Referral Link Unavailable'}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleConnect}
                style={{
                  margin: 'auto',
                  padding: '10px 20px',
                  fontSize: '18px',
                  display: 'block',
                  position: 'relative',
                  zIndex: '2',
                  border: '1px solid #00B8FF',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                }}
              >
                Connect Wallet
              </Button>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px' }}>
          <RootStyle
            sx={{
              boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              borderRadius: '2em',
              border: '1px solid #00B8FF'
            }}
          >
            <IconWrapperStyle>
              <Iconify icon="ic:outline-attach-money" width={38} height={38} />
            </IconWrapperStyle>
            <Typography variant="h5">
              Earn 5% of harvesting fees from anyone that uses your referral link! That's nearly 10¢
              per NFT!
            </Typography>
          </RootStyle>
          <RootStyle
            sx={{
              boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              borderRadius: '2em',
              border: '1px solid #00B8FF'
            }}
          >
            <IconWrapperStyle>
              <Iconify icon="eva:people-outline" width={38} height={38} />
            </IconWrapperStyle>
            <Typography variant="h5">
              Anyone that uses your referral link will get a 5% discount on NFT loss harvesting!
            </Typography>
          </RootStyle>
          <RootStyle
            sx={{
              boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              borderRadius: '2em',
              border: '1px solid #00B8FF',
              height: '400px',
              width: '600px',
              maxWidth: '600px'
            }}
          >
            <IconWrapperStyle>
              <Iconify icon="mdi:crown-outline" width={38} height={38} />
            </IconWrapperStyle>
            <Typography variant="h3">Leaderboard</Typography>
            <Leaderboard />
          </RootStyle>
        </div>
      </Container>
    </Page>
  );
}
