import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, ...other }, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <title>
        Jaypeggers | A tax-loss harvesting solution that facilitates the sale of illiquid and
        otherwise worthless NFTs. By providing exit liquidity we enable our users to turn their
        losses into savings.
      </title>
    </Helmet>
    {children}
    {/* <h1 style={{ width: '100%', textAlign: 'center', fontSize: '32px' }}>
      Paused while integrating new features and conducting audits
</h1> */}
  </Box>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
