import React from 'react';
import './style.css';
import PropTypes from 'prop-types';

function ImageContainer(props) {
  const { onImageClick, img, floor, thirty } = props;
  return (
    <div className="imgPickerContainer">
      {img.selected && (
        <button
          type="button"
          className="selected-container"
          onClick={() => onImageClick(img.index)}
        >
          <div className="selectory">SELECTED</div>
        </button>
      )}
      {img.link !== '' && (
        <div className="opensea">
          <a href={img.link} target="_blank" rel="noreferrer">
            <img alt="opensea" src="/static/opensea.png" />
          </a>
        </div>
      )}
      <button
        type="button"
        onClick={() => !img.selected && onImageClick(img.index)}
        className={img.selected ? 'a-selected' : 'a-un'}
      >
        <div className="floor">
          {img.title.substring(0, 20)}
          <br />
          Floor: {floor} ETH
          <br />
          30D: {thirty} ETH
        </div>
        {img.src && img.src !== '' && img.src !== null ? (
          <img
            loading="lazy"
            data-tip={img.title}
            data-for="images"
            src={img.src}
            alt={img.title}
            key={img.index}
            className={img.selected ? 'selected' : 'imgPicker'}
          />
        ) : (
          <div
            style={{
              verticalAlign: 'middle',
              display: 'table-cell',
              maxWidth: '230px',
              maxHeight: '220px',
              width: '230px',
              height: '220px',
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              whiteSpace: 'pre-line',
              padding: '10px'
            }}
            className={img.selected ? 'selected' : 'imgPicker'}
          >
            {img.title}
          </div>
        )}
      </button>
    </div>
  );
}
ImageContainer.propTypes = {
  onImageClick: PropTypes.func.isRequired,
  img: PropTypes.object.isRequired,
  floor: PropTypes.string.isRequired,
  thirty: PropTypes.string.isRequired
};

export default ImageContainer;
