import './style.css';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

function CollectionContainer(props) {
  const { onImageClick, img } = props;
  return (
    <div className="imgPickerContainer">
      <div className="opensea">
        <a href={img.link} target="_blank" rel="noreferrer">
          <img alt="opensea" src="/static/opensea.png" />
        </a>
      </div>
      <button
        type="button"
        onClick={() => !img.selected && onImageClick(img)}
        className={img.selected ? 'a-selected' : 'a-un'}
      >
        <div className="floor">
          {img.title ? img.title.substring(0, 20) : ''}
          <br />
          Floor: {img.floor} ETH
          <br />
          30D: {img.thirty} ETH
        </div>
        {img.src && img.src !== '' && img.src !== null ? (
          <img
            loading="lazy"
            data-tip={img.title}
            data-for="images"
            src={img.src}
            alt={img.title}
            key={img.index}
            className={img.selected ? 'selected' : 'imgPicker'}
            style={{ objectFit: 'cover', minHeight: 224 }}
          />
        ) : (
          <div
            style={{
              verticalAlign: 'middle',
              display: 'table-cell',
              maxWidth: '230px',
              maxHeight: '220px',
              width: '230px',
              height: '220px',
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              whiteSpace: 'pre-line',
              padding: '10px'
            }}
            className={img.selected ? 'selected' : 'imgPicker'}
          >
            {img.title}
          </div>
        )}
      </button>
    </div>
  );
}
CollectionContainer.propTypes = {
  onImageClick: PropTypes.func.isRequired,
  img: PropTypes.object.isRequired
};

export default CollectionContainer;
