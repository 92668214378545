import styled from 'styled-components';

export const Banner = styled('div')`
  display: none;
  height: 36px;
  width: 100vw;
  font-weight: bold;
  background: #ff8155;
  padding-top: 2px;
  padding-bottom: 2px;

  font-size: 18px;
  border-bottom: 4px solid rgb(0, 184, 255);
  color: white;
  text-overflow: clip;
  overflow-x: hidden;
  overflow-y: visable;
  white-space: nowrap;
  text-overflow: ellipsis ellipsis;
  text-overflow: ellipsis ' [..]';
  z-index: 999;
  position: fixed;
`;
