// material
import { Container } from '@mui/material';
import { useContext, useState } from 'react';

// components
import { alpha, styled } from '@mui/material/styles';

import Page from '../components/Page';
import StakeInfoCard from '../components/StakeInfoCard';
import { Web3Context } from '../hooks/WalletContext';
import { JAY_CONTRACT_, LP_STAKING_, LP_TOKEN_ } from '../hooks/constants/contracts';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  '@media (min-width: 600px)': {
    marginTop: '100px'
  }
}));

export default function StakeAll() {
  const { chainId } = useContext(Web3Context);
  const pairs = [
    {
      title: 'JAY/USDC LP',
      reward: 'ETH',
      stakingAddress: LP_STAKING_[chainId].address,
      pairAddress: LP_TOKEN_[chainId].address,
      rewardTokenAddress: 'native',
      tokenAddress: JAY_CONTRACT_[chainId].address,
      liqTokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
    },
    {
      title: 'jUSDC/ETH LP',
      reward: 'USDC',
      stakingAddress: '0xF70C2657C4135C2cE81977C32F7bC1E012A7226d',
      pairAddress: '0x893Be9aca9bE32a9bCE005D034E4C1facd4709Ac',
      rewardTokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      tokenAddress: '0xca9f9671765F8D1A7e19ae2639E01FFF730f0D9B',
      liqTokenAddress: 'ETH'
    }
  ];
  return (
    <Page title="Jaypeggers | Stake">
      <Container>
        <RootStyle>
          {pairs.map((pair) => (
            <div style={{ margin: 'auto', paddingLeft: 25, paddingRight: 25 }}>
              <StakeInfoCard props={pair} />
            </div>
          ))}
        </RootStyle>
      </Container>
    </Page>
  );
}
