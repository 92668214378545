// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={24} height={24} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'swap',
    path: '/swapJay',
    icon: getIcon('fe:loop')
  },
  {
    title: 'Stake',
    path: '/stake',
    icon: getIcon('carbon:piggy-bank')
  },
  {
    title: 'Tax Loss Harvest NFTs',
    path: '/sellNFTs',
    icon: getIcon('bi:trash')
  },
  {
    title: 'NFT Thrift Store',
    path: '/buyNFTs',
    icon: getIcon('clarity:store-solid')
  },
  {
    title: 'Get Referral Link',
    path: '/referral',
    icon: getIcon('game-icons:three-friends')
  }
];

export default sidebarConfig;
