import PropTypes from 'prop-types';
import { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Stack, Image } from '@mui/material';
import { Textfit } from 'react-textfit';
import { useConnectWallet } from '@web3-onboard/react';

// mocks_
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import sidebarConfig from './SidebarConfig';
import { Web3Context } from '../../hooks/WalletContext';
import LanguagePopover from './LanguagePopover';
import DiscordPopover from './DiscordPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  maxHeight: '-webkit-fill-available',
  overflowX: 'hidden',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: '3em',
  backgroundColor: theme.palette.grey[0]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [{ wallet }, connect] = useConnectWallet();
  const { userNftbank, ens, myRef } = useContext(Web3Context);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const ensConcat =
    ens?.length > 18
      ? ens
          .substring(0, 7)
          .concat('...')
          .concat(ens.substring(ens.length - 7, ens.length))
      : ens;
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = useResponsive('up', 'lg');
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        maxHeight: '-webkit-fill-available',
        maxWidth: '-webkit-fill-available',
        overflowX: 'hidden',
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box
        sx={{ justifyContent: 'center', overflow: 'hidden', display: 'inline-flex', width: '100%' }}
      >
        <Logo />
      </Box>

      {wallet ? (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle
              sx={{
                boxShadow:
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                border: 'none'
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: 'text.secondary',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <Textfit mode="single" forceSingleModeWidth={false} max={24}>
                  {myRef.length > 0
                    ? myRef
                    : ensConcat?.length > 0
                    ? ens
                    : wallet.accounts[0]?.ens?.name ||
                      (wallet.accounts[0].address.length > 18
                        ? wallet.accounts[0].address
                            .substring(0, 7)
                            .concat('...')
                            .concat(
                              wallet.accounts[0].address.substring(
                                wallet.accounts[0].address.length - 7,
                                wallet.accounts[0].address.length
                              )
                            )
                        : wallet.accounts[0].address)}
                </Textfit>
              </Typography>
            </AccountStyle>
          </Link>
        </Box>
      ) : (
        <Box
          sx={{ mb: 5, mx: 2.5 }}
          onClick={async () => {
            await connect();
          }}
        >
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle
              sx={{
                boxShadow:
                  'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                border: 'none'
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: 'text.secondary', textAlign: 'center', width: '100%' }}
              >
                Connect Wallet
              </Typography>
            </AccountStyle>
          </Link>
        </Box>
      )}

      <NavSection navConfig={sidebarConfig} />

      <Stack
        direction="row"
        alignItems="center"
        style={{
          textAlign: 'center',
          height: '70px',
          position: 'absolute',
          bottom: '0px',
          right: '32px',
          left: '32px',
          zIndex: 10
        }}
      >
        {windowDimensions.height > 710 && (
          <div
            style={{
              marginTop: windowDimensions.height > 800 ? '-210px' : '-25px',
              width: '100%',
              display: 'flex'
            }}
          >
            <LanguagePopover />
            <DiscordPopover />
          </div>
        )}
      </Stack>
      {windowDimensions.height > 800 && (
        <a
          style={{ position: 'absolute', bottom: 0, width: '100%' }}
          href="https://github.com/Credshields/Audit-Reports/blob/main/JayPeggers%20Final%20Audit%20Report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ margin: '0 auto' }}
            alt="Credshields Audit Report"
            src="/static/CSAudited.png"
            width="280"
          />
        </a>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
