import React, { useEffect, useRef } from 'react';

export const useAsyncEffect = (id, effect, setState, condtitions, timeout = 0, rerun = true) => {
  const isInitialMount = useRef(true);
  useEffect(() => {
    let isSubscribed = true;
    // // // console.log('Renderer - 11');
    async function fetchData() {
      if (condtitions() && (isInitialMount.current || rerun)) {
        // // console.log(`run - ${id}`);
        // // // console.log('Renderer - 1');

        setTimeout(async () => {
          const newState = await effect();
          isInitialMount.current = false;
          if (isSubscribed) setState((s) => ({ ...s, ...newState }));
        }, timeout);
      }
    }
    fetchData();
    return () => (isSubscribed = false);
  }, [effect, setState, condtitions, timeout, id, rerun]);
};
