import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// material
import { Box } from '@mui/material';
import logo from '../imgs/logo.gif';

// ----------------------------------------------------------------------
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  if (height <= 600 && width <= 900)
    return {
      width: '100px',
      display: 'none'
    };
  if (height <= 675 && width <= 900)
    return {
      width: '175px'
    };
  if (height < 675 && width < 900)
    return {
      width: '175px'
    };
  return {
    width: '100%'
  };
}

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <RouterLink to="/">
      <Box
        component="div"
        sx={{
          maxWidth: windowDimensions?.width,
          width: windowDimensions?.width,
          display: windowDimensions?.display,
          ...sx
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{
            width: '100%',
            ...sx
          }}
        />
      </Box>
    </RouterLink>
  );
}
