import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/material';
import './style.css';
import PropTypes from 'prop-types';
import { Web3Context } from '../../hooks/WalletContext';

function ImageContainer(props) {
  const { onImageClick, cancel, setApproved, img, approved, filters } = props;
  const [stats, setStats] = useState(null);
  const [lastSale, setLastSale] = useState(null);
  const { getFloor, getLastSale, ethprice } = useContext(Web3Context);
  useEffect(() => {
    getLastSale(img.address, img.id).then((collection) => {
      // // console.log(collection);
      setLastSale(collection?.lastSale || '?');
    });
  }, [img.id]);
  useEffect(() => {
    getFloor(img.address).then((collection) => {
      // // console.log(collection);
      setStats({
        floor: collection?.floor || '?'
      });
    });
  }, [img.id]);
  return (
    <div className="imgPickerContainer">
      {(img.approved || approved) && img.selected && (
        <button
          type="button"
          className="selected-container"
          onClick={() => onImageClick({ img: img.index, lastSale })}
        >
          <div className="selectory">SELECTED</div>
        </button>
      )}
      {!img.approved && !approved && img.selected && (
        <div className="selected-container">
          <button
            type="button"
            onClick={() => setApproved(img.index, img.address)}
            className="approve"
          >
            Approve Collection
          </button>
          <button type="button" onClick={() => cancel(img.index)} className="cancel">
            Cancel
          </button>
        </div>
      )}
      {img.approving && (
        <Box
          component="img"
          src="/static/eggload.png"
          sx={{
            p: '30px',
            animation: 'rotation 8s infinite linear',
            position: 'absolute',
            top: '-5px',
            left: '5px',
            bottom: '0',
            right: '0',
            zIndex: '5'
          }}
        />
      )}
      {img.link !== '' && (
        <div className="opensea">
          <a href={img.link} target="_blank" rel="noreferrer">
            <img alt="opensea" src="/static/opensea.png" />
          </a>
        </div>
      )}
      <button
        type="button"
        onClick={() => !img.selected && onImageClick({ img: img.index, lastSale })}
        className={
          img.selected
            ? 'a-selected'
            : Number(stats?.floor || 0) * ethprice < 15 &&
              parseFloat(lastSale) > 5 &&
              parseFloat(lastSale) > Number(stats?.floor || 0) * ethprice
            ? 'a-unBlink'
            : 'a-un'
        }
      >
        <div
          className={
            Number(stats?.floor || 0) * ethprice < 15 &&
            parseFloat(lastSale) > 5 &&
            parseFloat(lastSale) > Number(stats?.floor || 0) * ethprice
              ? 'recommended'
              : 'hide'
          }
        >
          Recommended <br /> Harvest
        </div>

        {stats !== null && (
          <div className="floor">
            {img.title.substring(0, 20)}
            <br />
            <br />
            Floor Price: ${(Number(stats.floor) * ethprice).toFixed(2)}
            <br />
            Est. {parseFloat(lastSale) - Number(stats.floor) * ethprice > 0 ? 'Loss: ' : 'Gain: '}
            <span
              style={{
                color: parseFloat(lastSale) - Number(stats.floor) * ethprice < 0 ? 'green' : 'red'
              }}
            >
              ${Math.abs(parseFloat(lastSale) - Number(stats.floor) * ethprice).toFixed(2)}
            </span>
          </div>
        )}
        {img.src && img.src !== '' && img.src !== null ? (
          <img
            loading="lazy"
            data-tip={img.title}
            data-for="images"
            src={img.src}
            alt={img.title}
            key={img.index}
            className={img.selected ? 'selected' : 'imgPicker'}
          />
        ) : (
          <div
            style={{
              verticalAlign: 'middle',
              display: 'table-cell',
              maxWidth: '230px',
              maxHeight: '220px',
              width: '230px',
              height: '220px',
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              whiteSpace: 'pre-line',
              padding: '10px'
            }}
            className={img.selected ? 'selected' : 'imgPicker'}
          >
            {img.title}
          </div>
        )}
      </button>
    </div>
  );
}
ImageContainer.propTypes = {
  setApproved: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
  img: PropTypes.object.isRequired,
  approved: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired
};

export default ImageContainer;
