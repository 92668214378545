import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

// ----------------------------------------------------------------------

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const BlinkedBox = styled('div')({
  backgroundColor: 'green',
  width: 10,
  height: 10,
  animation: `${blink} 2s linear infinite`,
  display: 'inline-block',
  borderRadius: '50%'
});
const BlinkedContainer = styled('div')({
  position: 'absolute',
  right: 20,
  '@media (min-width: 600px)': {
    right: 120
  },
  top: 120,
  fontSize: 14,
  zIndex: 1
});

export default function Live({ icon, sx, ...other }) {
  return (
    <BlinkedContainer>
      <BlinkedBox /> Live
    </BlinkedContainer>
  );
}
