import React from 'react';
import { Typography } from '@mui/material';

import useLeaderboard from '../hooks/useLeaderboard';

function Leaderboard() {
  const leaderBoard = useLeaderboard();
  console.log('render');
  return (
    leaderBoard?.data?.map((l) => (
      <div key={l.name} style={{ width: '100%' }}>
        <Typography
          variant="h5"
          style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}
        >
          {l.name}
        </Typography>
        <Typography
          variant="h5"
          style={{ display: 'inline-block', textAlign: 'right', width: '50%' }}
        >
          {l.value}
        </Typography>
      </div>
    )) || <span />
  );
}
export default React.memo(Leaderboard);
