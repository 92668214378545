// material
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        '.textLeft': {
          textAlign: windowDimensions?.width < 750 ? 'left' : 'right'
        },
        ':root': {
          '--onboard-modal-z-index': 7,
          '--account-center-z-index': 9,
          '--onboard-wallet-button-box-shadow':
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important',
          '--account-center-box-shadow':
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important',
          '--account-center-border-radius': '3em',
          '--account-center-border': '0px solid white !important',
          '--account-center-micro-background': 'white',
          '--onboard-connect-content-height': '-webkit-fill-available',
          '--account-center-position-right':
            windowDimensions?.width < 600 ? '0px' : windowDimensions?.width < 1200 ? '8px' : '24px',
          '--account-center-position-top': '0px'
        },
        '.MuiContainer-maxWidthXl': {
          maxWidth: 'none !important'
        },
        '#w3a-modal': {
          zIndex: '7'
        },
        '#formcontrol fieldset': {
          border: 'none !important'
        },
        '#demo-simple-select-autowidth': {
          margin: '-10px',
          color: '#495057',
          fontWeight: '600',
          lineWeight: '1.5',
          fontSize: '1rem',
          fontamily: 'monospace',
          border: 'none'
        },
        'onboard-magic-login-modal': {
          zIndex: '7',
          position: 'absolute',
          top: '0',
          left: '100vw',
          all: 'revert-layer'
        },
        '::slotted( minimized )': {
          borderColor: 'black !important',
          border: 'none !important'
        },
        '.Mui-focused > fieldset': {
          borderColor: 'black !important'
        },
        '.MuiOutlinedInput-root > fieldset': {
          borderRadius: '10em !important'
        },
        '.Mui-disabled': {
          color: theme.palette.grey[600],
          textFillColor: '#495057 !important'
        },
        html: {
          width: '100%',
          height: '-webkit-fill-available',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '-webkit-fill-available'
        },
        '#root': {
          width: '100%',
          height: ['100vh', '-webkit-fill-available'],
          maxHeight: '100vh'
        },
        '.MuiDrawer-modal': {
          zIndex: '6 !important'
        },
        '.MuiDrawer-paperAnchorLeft': {
          zIndex: '6 !important'
        },
        header: {
          zIndex: '5 !important',
          background: 'rgba(0,0,0,0) !important',
          backdropFilter: 'none !important'
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            }
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::placeholder': {
            color: theme.palette.text.disabled
          }
        },

        img: { display: 'block', maxWidth: '100%' },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        }
      }}
    />
  );
}
